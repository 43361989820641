<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js'
import { interactionMixin } from 'src/modules/interactions_module.js'
import { get } from "lodash"

export default {
  mixins: [fandomBasicMixin, interactionMixin],
  methods: {
    getAnswerClasses(answer) {
      if (this.isInteractionDone(this.containerContent, this.content.interaction)) {
        const userInteraction = this.getUserInteraction(this.containerContent, this.content.interaction);
        return userInteraction.data.answer_id == answer.$id ? "choose" : "not-choose";
      }
    },
    getAnswerStrokeDashOffset: function(answer) {
      if (this.isInteractionDone(this.containerContent, this.content.interaction)) {
        return 365 - (365 * this.getAnswerPercentage(answer) / 100);
      } else {
        return 365;
      }
    },
    getAnswerPercentage(answer) {
      return this.getContentUserInfo(this.containerContent).id_to_interaction[this.content.interaction.id].id_to_answer[answer.$id].percentage;
    }
  },
  computed: {
    getUserAvatar() {
      return get(this.pageModel, ["user", "avatar", "url"], false);
    },
    getUserBadgeStyle() {
      let styles = {};
      if (this.getUserAvatar) {
        styles['background-image'] = `url(${this.getUserAvatar})`;
        styles['background-color'] = "transparent";
      }
      return styles;
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" class="bg-cover-center" :class="contentType" v-if="isContentVisibleToUser()" :style="{'background-image': getContentResponsiveImageURL('background_image'), 'background-color': content.background_color}" versus-component>
    <div class="container">
      <div class="row align-items-center justify-content-center mh-100vh py-4">
        <div class="col-md-8 text-center">
          <reward-marker class="mb-3" :interaction="content.interaction" :content="content" :container-content="containerContent"></reward-marker>
          <h2 class="pb-4" :style="{'color': content.text_color}">{{content.question}}</h2>
          <div class="row justify-content-center">
            <div class="col-4 col-md-3 m-3 p-0 answer" :class="getAnswerClasses(answer)" v-for="answer in content.answers" @click="updateVersusInteraction(containerContent, content, content.interaction, answer.$id)">
              <div class="ar-1by1">
                <div class="bg-cover-center position-absolute-center rounded-circle" :style="{'background-image': `url(${expandUrl(answer.image.url)})`}" :title="answer.image.title">
                  <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
                    <div v-if="isInteractionDone(containerContent, content.interaction)" class="answer-percentage position-absolute-center bg-gradient-thin rounded-circle d-flex justify-content-center align-items-center">
                      <span class="font-weight-bold align-self-end mb-3">{{getAnswerPercentage(answer)}}%</span>
                    </div>
                  </transition>
                  <div v-if="getAnswerClasses(answer) == 'choose'" class="small-avatar bg-cover-center d-flex flex-column justify-content-center align-items-center" :style="getUserBadgeStyle">
                    <i v-if="!getUserAvatar" class="far fa-user"></i>
                  </div>
                  <div class="svg-container fill" v-if="isInteractionDone(containerContent, content.interaction)">
                    <svg viewBox="0 0 120 120" class="position-absolute-center">
                      <circle cx="60" cy="60" r="58" stroke-width="4" fill="none" transform="rotate(90, 60, 60)"/>
                    </svg>
                  </div>
                  <div class="svg-container">
                    <svg viewBox="0 0 120 120" class="position-absolute-center">
                      <circle cx="60" cy="60" r="58" stroke-width="4" fill="none" transform="rotate(-90, 60, 60)" :style="{'stroke-dashoffset': getAnswerStrokeDashOffset(answer)}"/>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
      <component :is="content.feedback_layout || 'interaction-feedback-alert'" v-if="isInteractionFeedbackPhase(containerContent, content.interaction)" :content="content" :opt-container-content="containerContent" :interaction="content.interaction"></component>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
[versus-component] {

  .small-avatar {
    width: 3rem;
    height: 3rem;
    top: calc(14.64% - 1.5rem);
    left: calc(14.64% - 1.5rem);
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    background-color: #{$user-badge-bg-color};

    .far {
      font-size: 1.5rem;
      color: #{$user-badge-color};
    }
  }

  .answer {
    cursor: pointer;
    position: relative;

    .answer-percentage {
      font-size: 1.5rem;
      color: $primary;
    }

    .svg-container svg circle {
      stroke: $primary;
      transition: all $animation-time-slow linear;
      stroke-dashoffset: 365; // svg circle circumference
      stroke-dasharray: 365;
    }

    .svg-container.fill svg circle {
      stroke: $gray-400;
      stroke-dashoffset: 730;
    }
  }

  @include media-breakpoint-down(xs) {
    .answer .answer-percentage {
      font-size: 2rem;
    }
  }
}
</style>