<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data: function () {
    return { };
  },
  computed: {
    rootStyle() {
      return {
        "background-image": this.getContentResponsiveImageURL("background_image"),
        "background-color": this.content.background_color
      };
    }
  },
  created() {
    if (this.content.theme) {
      this.content.stripes.forEach((stripe) => {
        if (!Fandom.exists(stripe.theme)) {
          Vue.set(stripe, "theme", this.content.theme);
        }
      });
    }
  },
};
</script>

<template>
  <div 
    v-easyadmin="easyadminId" 
    :class="[contentType, {'secondary-navbar-present': !!content.navbar}, theme]" 
    :style="rootStyle"
    v-if="isContentVisibleToUser()" 
    page-blank-component
  >
    <component :is="getContent(content.navbar).layout" :content="getContent(content.navbar)" v-if="content.navbar" :animation="false" :sticky="true"></component>
    <component :is="stripe.layout" :content="stripe" v-for="(stripe, index) in content.stripes" :key="stripe.$id" :opt-container-content="containerContent" :class="{'first': !index}"></component>
  </div>
</template>

<style lang="scss">
[page-blank-component] {
  background-color: var(--theme-background);
  background-size: 100% auto;
  position: relative;

  &.secondary-navbar-present .breadcrumb-container {
    top: $secondary-nav-height;
  }

  &.secondary-navbar-present {
    .first .mh-100vh-header {
      min-height: calc(100vh - #{$header-height} - #{$secondary-nav-height});
    }

    .first .h-100vh-header {
      height: calc(100vh - #{$header-height} - #{$secondary-nav-height});
    }
  }

}
</style>
