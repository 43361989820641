<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { sendFandomAnalyticsEvent } from "src/modules/fandom_analytics_module.js";
import shave from "shave";
import { get } from 'lodash';

export default {
  mixins: [fandomBasicMixin],
  props: {
    showAuthorMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      bgVideoActive: false,
    }
  },
  mounted() {
    Vue.nextTick(() => {
      const height = ($(this.$el).height() / 2);
      if (!!this.content.title)
        shave(".tile-title", height);
    })
  },
  methods: {
    getContentURL: Fandom.getContentURL,
    getBackgroundUrl: Fandom.getBackgroundUrl,
    getTileThumbnailUrl: Fandom.getTileThumbnailUrl,
    clickHandler(event) {
      const target = $(event.currentTarget);
      const href = target.attr("href");

      if (href == "javascript:void(0)") {
        return false;
      }

      if (target.attr("target") == "_blank") {
        window.open(href, '_blank');
      } else {
        window.location.href = href;
      }

      sendFandomAnalyticsEvent("Interaction", "Grid Tile Click", this.containerContent.name);
      return false;
    },
  },
  computed: {
    contentUrl() {
      const result = this.getContentURL(this.content);
      return result || "javascript:void(0)";
    },
    isVideo() {
      return this.content.parent_names && (this.content.parent_names.includes("course-preview") || this.content.parent_names.includes("course"));
    },
    icon() {
      return this.content.icon ? this.content.icon : (this.decorator && this.decorator.icon ? this.decorator.icon : null);
    },
    category() {
      return this.decorator ? this.decorator.title : this.content.category;
    },
    authorText() {
      if (this.content.alternative_author) {
        return this.content.alternative_author;
      } else if (this.content.author && Array.isArray(this.content.author)) {
        return this.content.author.map( a => a.member_name ).join(', ');
      } else if (this.content.author && this.content.author.member_name) {
        return this.content.author.member_name;
      } else {
        return null;
      }
    },
    backgroundVideo() {
      return (globalState.viewport.sm || globalState.viewport.xs) ? (this.content.tile_video_mobile || this.content.tile_video) : this.content.tile_video;
    }
  }
};
</script>

<template>
  <div 
    v-easyadmin="easyadminId" 
    :class="contentType"
    class="position-absolute-center bg-transition text-white" 
    tile-component
  >
    <a :href="contentUrl" 
      :target="getTarget(content.url)" 
      @click.prevent="clickHandler($event)"
      @mouseenter="bgVideoActive = true"
      @mouseleave="bgVideoActive = false"
    >
      <div class="bg-cover-center position-absolute-center bg" :style="{'background-image': getTileThumbnailUrl()}"></div>
      <div class="position-absolute-center linear-gradient-y" v-if="!content.hide_gradient"></div>
      <div v-if="bgVideoActive && backgroundVideo" class="position-absolute-center">
        <video autoplay muted loop webkit-playsinline playsinline class="w-100 h-100">
          <source :src="backgroundVideo.url" type="video/mp4">
        </video>
      </div>
      <div class="position-absolute-center">
        <div class="tile__header text-white px-3">
          <img v-if="icon" class="tile__decorator-icon" :src="icon.url" />
          <p v-else-if="category" class="tile__category text-uppercase text-white"><small>{{category}}</small></p>
        </div>
      
        <div class="tile__footer position-absolute-center d-flex flex-column justify-content-end p-3 mx-0">
          <div class="d-flex justify-content-between align-items-end">
            <div class="text-white w-100">
              <p class="mb-0 author" :class="{'d-none d-md-block': !showAuthorMobile}" v-if="authorText">{{authorText}}</p>
              <p class="m-0 font-weight-bold tile-title" :class="{'text-truncate': content.truncate}">{{content.title}}</p>
            </div>
            <div class="d-flex flex-column tile__icons ml-3">
              <div class="tile__buy-icon mb-md-3" v-if="!!content.external_shop">
                <i class="fal fa-cart-arrow-down"></i>
              </div>
              <div class="tile__video-icon pl-1 d-none d-md-flex" v-if="isVideo">
                <i class="fas fa-play"></i>
              </div>
            </div>
            <div class="w-75 mt-3 tile__btn-group d-flex flex-column flex-md-row flex-wrap" v-if="content.button || content.buttons">
              <a
                if="content.button" 
                role="button" 
                class="btn shadow-none" 
                :class="content.button_class || 'btn-light'"
                :href="content.button.url"
              >
                {{content.button.title}}
              </a>
              <a 
                v-for="(button, index) in content.buttons" 
                :key="index"
                role="button" 
                class="btn shadow-none" 
                :class="content.button_class || 'btn-light'"
                :href="button.button.url"
              >
                {{button.button.title}}
              </a>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<style lang="scss" scoped>
[tile-component] {

  .tile-title {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tile__icons {

    .tile__video-icon,
    .tile__buy-icon {
      border-radius: 100%;
      border: 1px solid $white;
      width: 2.5rem;
      height: 2.5rem;
      line-height: 2.5;
      text-align: center;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .tile__video-icon {
      border: 1px solid $white;
      color: white;
    }

    .tile__buy-icon {
      color: $dark;
      background-color: $white;
    }
  }

  .tile__decorator-icon {
    width: 6rem;
  }

  .tile__category {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  .tile__btn-group {
    margin-left: -0.25rem;
    margin-right: -0.25rem;

    .btn {
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }
  }

  .tile__footer {
    padding: 1.75rem;

    .tile-footer-img-container img {
      width: 5rem;
    }
  }

  .bg {
    transition-duration: 3s;
    transition-timing-function: cubic-bezier(0.23,1,0.32,1);
  }

  @include media-breakpoint-down(sm) {

    .tile__button-group .btn {
      padding-left: 1.5rem;
      padding-right: 1.5rem;

      margin-top: .5rem;
      margin-bottom: .5rem;
    }
  }
}
</style>
