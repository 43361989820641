<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { get } from 'lodash';

export default {
  mixins: [fandomBasicMixin],
  mounted: function() {},
  computed: {
    backgroundColor() {
      return get(this, ['content','background_color'], null) || get(this, ['decorator','color'], null);
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" v-if="isContentVisibleToUser()" :class="contentType" stripe-one-col-media-inline-component>
    <div class="bg-gray-800" :style="{'background-color': `${backgroundColor} !important`}" v-if="content.embed_media || exists(content.play) || exists(content.image)">
      <div class="container">
        <div class="row">
          <div class="col-12 p-0">
            <div v-if="content.embed_media" v-html="content.embed_media">
            </div>
            <component 
              v-else-if="exists(content.play)" 
              class="embed-responsive embed-responsive-16by9" 
              :is="`embed-${content.play.media_type}`"  
              :content="content" 
              :opt-container-content="containerContent">
            </component>  
            <div v-else-if="content.image" class="bg-cover-center ar-16by9 w-100" :style="{'background-image': `url(${content.image.url})`}"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>