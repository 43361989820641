<script>
import { get } from 'lodash';
import { hfarmCoursesAuthorizationMixin } from '../../modules/hfarm_courses_mixins_module.js';

export default {
  mixins: [hfarmCoursesAuthorizationMixin],
  data() {
    return {
      pageModel: globalState.pageModel,
      inheritContent: null
    };
  },
  mounted() {
    Vue.nextTick(() => {
      Fandom.onEvent("modal-user-group-required", (data) => {
        Vue.set(this, "inheritContent", data.inheritContent);
        $("#user-modal-group-required").modal("show");
      });
    });
  },
  methods: {
    setAuthorization(data) {
      if (data.success) {
        Fandom.emit("modal-user-group-succeeded");
      }
    }
  },
  computed: {
    formContent() {
      if (this.inheritContent) {
        const authRecord = get(globalState.pageModel, ["name_to_content_authorization", this.inheritContent.name], null);
        const userGroup = get(authRecord, ["data", "authorization_record", "user_group"], null);
        return get(globalState.pageModel, ["layout", "content", "user_group_forms", userGroup], null);
      } else {
        return null;
      }
    }
  }
};
</script>

<template>
  <div modal-user-group-required-component>
    <div id="user-modal-group-required" class="modal fade" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header border-bottom-0 pb-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center" registration-pages>
            <div class="row justify-content-center">
              <div class="col-lg-10" v-if="formContent">
                <h5 class="modal-title h2">{{formContent.title}}</h5>
                <div v-if="formContent.description" class="user-edit-description" v-html="formContent.description"></div>
                <generic-form class="my-5 text-left" :content="formContent" @done="setAuthorization" :opt-container-content="pageModel.layout.content"></generic-form>
              </div>
            </div>  
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[modal-user-group-required-component] {

  [generic-form-component]::v-deep {
    .form-control {
      border: 1px solid $gray-400;
    }

    .form-group {
      text-align: left;
    }

    .progress {
      display: none;
    }
  }
}
</style>