<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { interactionMixin } from "src/modules/interactions_module.js";

export default {
  mixins: [fandomBasicMixin, interactionMixin],
  data: function() {
    return {};
  },
  mounted() {
    if (this.isInteractionDone(this.containerContent, this.content.interaction)) {
      setTimeout(() => {
        this.$emit("interaction-done", {});
      }, 3000);
    }
  },
  methods: {
    getAnswerClasses(answer) {
      if (this.isInteractionDone(this.containerContent, this.content.interaction)) {
        if (this.isAnswerCorrect(this.containerContent, this.content.interaction, answer.$id)) {
          return "btn-success";
        } else {
          const userInteraction = this.getUserInteraction(this.containerContent, this.content.interaction);
          const userInteractionAnswerId = Fandom.exists(userInteraction.answer_id) ? userInteraction.answer_id : parseInt(userInteraction.data.answer_id);
          return userInteractionAnswerId == answer.$id ? "btn-danger" : "btn-light";
        }
      } else {
        return "btn-light";
      }
    }
  },
  watch: {
    // The feedback phase is managed by the interaction module. When it ends the parent is notify to continue the video
    isThisInteractionFeedbackPhase(newValue, previousValue) {
      if (previousValue && !newValue) {
        this.$emit("interaction-done", {});
      }
    }
  },
  computed: {
    isThisInteractionFeedbackPhase() {
      return this.isInteractionFeedbackPhase(this.containerContent, this.content.interaction);
    }
  }
};
</script>

<template>
  <div course-product-video-chapter-trivia-interaction-component class="bg-dark d-flex flex-column justify-content-center align-items-center text-white p3">
    <h3>{{content.question}}</h3>
    <ul class="list-unstyled my-3 d-flex">
      <li v-for="answer in content.answers" class="mx-2">
        <button @click="updateTriviaInteraction(containerContent, content, content.interaction, answer.$id)" class="btn" :class="getAnswerClasses(answer)">
          {{answer.text}}
        </button>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
[course-product-video-chapter-trivia-interaction-component] {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
</style>