<script>
import { filter, get } from "lodash";
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { interactionMixin } from "src/modules/interactions_module.js";

export default {
  mixins: [fandomBasicMixin, interactionMixin],
  data() {
    return {
      overvideoInteraction: null,
      videoPlayer: {},
      progressBarDragging: false,
      progressBarValue: 0
    };
  },
  watch: {
    // Update the progress bar
    "videoPlayer.progress"(newValue, previousValue) {
      if (!this.progressBarDragging) {
        this.progressBarValue = newValue;
      }
    },
    "videoPlayer.end"(newValue, previousValue) {
      if (newValue) {
        this.toggleVideoPopup();
      }
    }
  },
  mounted() {
    if (this.progressBarRef) {
      this.progressBarRef.addEventListener("input", this.progressInputHandler);
      this.progressBarRef.addEventListener("change", this.progressChangeHandler);
    }

    /* When the openFullscreen() function is executed, open the video in fullscreen.
    Note that we must include prefixes for different browsers, as they don't support the requestFullscreen method yet */
    if (this.$el.requestFullscreen) {
      this.$el.requestFullscreen();
    } else if (this.$el.mozRequestFullScreen) { /* Firefox */
      this.$el.mozRequestFullScreen();
    } else if (this.$el.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      this.$el.webkitRequestFullscreen();
    }
  },
  computed: {
    // The component can contain a progress bar with progress-bar reference.
    progressBarRef() {
      return this.$refs["progress-bar"];
    },
    inPause() {
      return Fandom.exists(this.videoPlayer.playing) && !this.videoPlayer.playing && !this.overvideoInteraction;
    },
    isVideoInteractive() {
      return get(this.content, ["play", "videoConfig", "interactive"], false);
    }
  },
  methods: {
     // Event corresponding to the input range drag start. Together with progressChangeHandler manages the progress bar update
    progressInputHandler() {
      this.progressBarDragging = true; 
    },
    // Event corresponding to the input range drag end
    progressChangeHandler() {
      this.videoPlayer.skipToSecond(this.progressBarValue * this.videoPlayer.getDuration() / 100);
      this.progressBarDragging = false;
    },
    toggleVideoState() {
      if (this.videoPlayer.playing) {
        this.videoPlayer.pause();
      } else {
        this.videoPlayer.play();
      }
    },
    toggleVideoPopup() {
      Vue.delete(globalState, "videoPopupContent");
    },
    chapterStatus(chapter) {
      const interactionRecord = chapter.interaction;
      if (this.isInteractionDone(this.containerContent, interactionRecord.interaction)) {
        const userInteraction = this.getUserInteraction(this.containerContent, interactionRecord.interaction);
        if (interactionRecord.interaction.type == "TestStep") {
          return true;
        } else {
          return get(userInteraction, ['data', 'answer_status'], true) ? true : false;
        }
      } else {
        return null;
      }
    }
  }
};
</script>

<template>
  <div class="bg-dark" video-popup-component>
    <div class="video-popup__embed-container">
      <component 
        ref="embed-video"
        v-if="exists(content.play)" 
        class="position-absolute-center"
        :is="`embed-${content.play.media_type}`"  
        :content="content" 
        :opt-container-content="containerContent"
        @video-player-handler="videoPlayer = arguments[0]"
        @overvideo-interaction-handler="overvideoInteraction = arguments[0]">
      </component>  
      <div v-if="isVideoInteractive" class="position-absolute-center video-popup__prevent-action"></div>
      <div v-if="isVideoInteractive" class="position-absolute-center cursor-pointer" @click="toggleVideoState()"></div>
      <div v-if="isVideoInteractive && videoPlayer.muted" class="m-3 px-2 py-1 text-white bg-dark d-flex align-items-center video-popup__unmute" @click="videoPlayer.changeVolume(0.5)">
        <small><i class="fal fa-volume-slash mr-2"></i>umuted</small>
      </div>
    </div>

    <div
      v-if="inPause && isVideoInteractive"
      class="bg-black text-white position-absolute-center video-popup__pause d-flex align-items-center justify-content-center"
      @click="toggleVideoState()">
      <i class="fal fa-pause"></i>
    </div>

    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
      <component
        v-if="overvideoInteraction"
        :is="overvideoInteraction.layout"
        class="bg-black text-white position-absolute-center"
        :content="overvideoInteraction"
        :opt-container-content="containerContent"
        @interaction-done="$refs['embed-video'].unsetOvervideoInteraction()"
      ></component>
    </transition>
    
    <template>
      <div v-if="isVideoInteractive" v-show="exists(videoPlayer.playing)" class="video-popup__progress-bar d-flex align-items-center">
        <input ref="progress-bar" type="range" step="0.001" min="0" max="100" v-model="progressBarValue" class="video-popup__progress-bar-input">
        <div class="video-popup__progress-wrapper">
          <div class="video-popup__progress" :style="{'width': `${progressBarValue}%`}"></div>
          <div class="video-popup__progress-background"></div>
        </div>
        <div
          class="video-popup__progress-bar-chapter d-flex align-items-center justify-content-center"
          v-for="(chapter, index) in content.chaptering"
          :key="`chapter-${index}`"
          :style="{left: chapter.position}"
          :class="{'active': chapter.active, 'wrong': chapterStatus(chapter) == false, 'correct': chapterStatus(chapter) == true}"
          @click="videoPlayer.skipToSecond(chapter.seconds)"
          >
          <i
            class="far"
            :class="{'fa-question': !exists(chapterStatus(chapter)), 'fa-thumbs-down': chapterStatus(chapter) == false, 'fa-thumbs-up': chapterStatus(chapter) == true}"
            ></i>
        </div>
      </div>
    </template>

    <div class="video-popup__close" @click.stop="toggleVideoPopup()">
      <span aria-hidden="true">&times;</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@mixin thumb-styles($selector) {
  #{$selector}::-webkit-slider-thumb {
    @content
  }
  #{$selector}::-moz-range-thumb {
    @content
  }
  #{$selector}::-ms-thumb {
    @content
  }
}

[video-popup-component] {
  $progress-icon-size: 2rem;
  $video-progress-bar-marker-size: 2rem;
  $bar-height: .375rem;

  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1090;
  height: 100vh;

  .video-popup__unmute {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1050;
  }

  .video-popup__prevent-action {
    background-color: transparent;
  }

  .video-popup__progress-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: $bar-height;
    border-radius: .25rem;
  }

  .video-popup__progress {
    width: 101%;
    height: 100%;
    position: absolute;
    top: 0;
    background: $primary;
  }
  
  .video-popup__progress-background {
    width: 100%;
    height: 100%;
    background-color: $white;
    border: 1px solid $gray-200;
  }

  .video-popup__pause i {
    font-size: 5rem;
  }

  .video-popup__close {
    position: absolute;
    top: 0rem;
    right: 1rem;
    color: $white;
    cursor: pointer;
    font-size: 2rem;

    @include media-breakpoint-down(sm) {
      top: 1rem;
      right: 2rem;
    }
  }

  .video-popup__embed-container {
    height: 100vh;
    position: relative;
  }

  .video-popup__progress-bar {
    position: fixed;
    bottom: 35px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 75%;

    .video-popup__progress-bar-input {
      width: 100%;
      appearance: none;
      height: $bar-height * 6;
      outline: none;
      position: absolute;
      top: -$bar-height * 3;
      left: 0;
      background-color: transparent;
      z-index: 1;

      @include thumb-styles("&") {
        appearance: none;
        width: $video-progress-bar-marker-size / 2;
        height: $video-progress-bar-marker-size / 2;
        border-radius: 100%;
        border: none;
        bottom: -$bar-height / 2;
        background: $primary !important;
        cursor: pointer;
        position: relative;
        z-index: 3;
      }
    }

    .video-popup__progress-bar-chapter {
      position: absolute;
      top: unset;
      width: $video-progress-bar-marker-size;
      height: $video-progress-bar-marker-size;
      background-color: $primary;
      border: 1px solid $primary;
      color: $white;
      border-radius: 100%;
      cursor: pointer;
      z-index: 2;

      .far {
        font-size: $video-progress-bar-marker-size * 0.5;
      }

      &.active {
        // background-color: $gray-300;
        background-color: $white;
        border: 0 solid $primary;
        box-shadow: 0 0 0 $video-progress-bar-marker-size * 0.175 inset $primary;
      }

      &.correct {
        background-color: $success;
        border-color: $success;
      }

      &.wrong {
        background-color: $danger;
        border-color: $danger;
      }
    }
  }

  ::v-deep [embed-kaltura-component] > div {
    height: 100vh;
  }

  @include media-breakpoint-down(sm) {

    .video-popup__progress-bar {

      .video-popup__progress-bar-input {
        @include thumb-styles("&") {
          width: $video-progress-bar-marker-size * 0.75 + .115rem;
          height: $video-progress-bar-marker-size * 0.75 + .115rem;
        }
      }

      .video-popup__progress-bar-chapter {
        width: $video-progress-bar-marker-size * 0.75;
        height: $video-progress-bar-marker-size * 0.75;
        .far {
          font-size: $video-progress-bar-marker-size * 0.30;
        }
      }
    }
  }
}
</style>
