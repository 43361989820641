<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import Intersect from 'vue-intersect';

export default {
  mixins: [fandomBasicMixin],
  components: {
    Intersect
  },
  methods: {
    recaptchaIsActive() {
      return !!globalState.pageModel.aux.recaptcha_enterprise;
    },
    getTarget: Fandom.getTarget,
    emit: Fandom.emit,
    getLangLink(lang) {
      const slug = globalState.pageModel.name_to_content[globalState.pageModel.main_content_name].slug;
      return Fandom.urljoin("/", lang, slug);
    }
  }
};
</script>

<template>
  <div footer-component>
    <intersect @enter="emit('footerEnter')" @leave="emit('footerLeave')">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 py-3">
            <div class="row justify-content-between py-md-5 d-none d-md-flex" v-if="content.navbar">
              <div v-for="section in content.navbar" class="col-12 col-md">
                <div class="text-uppercase text-white py-2 section-title">{{section.title}}</div>
                <div v-for="(item, index) in section.items" class="w-100 py-1" :key="index">
                  <a class="navbar-link" :href="item.url.url">{{item.url.title}}</a>
                </div>
              </div>
            </div>
            <div class="row justify-content-between py-3 d-md-none" v-if="content.navbar">
              <div v-for="(section, section_index) in content.navbar" class="col-12">
                <a
                  :href="`#section-${section_index}`"
                  data-toggle="collapse"
                  class="font-weight-bold text-uppercase text-white py-2 section-title w-100 d-flex justify-content-between collapsed"
                >
                  {{section.title}}
                  <i class="far fa-chevron-up d-flex justify-content-center align-items-center"></i>
                </a>
                <div :id="`section-${section_index}`" class="collapse">
                  <div v-for="(item, index) in section.items" class="w-100 py-1" :key="index">
                    <a class="navbar-link" :href="item.url.url">{{item.url.title}}</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-between align-items-center py-3">
              <div class="col-12 d-flex justify-content-between justify-content-md-start">
                <div v-for="(icon, index) in content.social_icons" class="mr-md-5" :key="index">
                  <a :href="icon.url.url" class="social-icon" :target="getTarget(icon.url)">
                    <i :class="icon.icon"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 bg-gray-800 py-3 py-md-0">
            <div class="row py-2 py-md-4 justify-content-center">
              <div class="col-12 col-md-10 d-flex align-items-md-center flex-column justify-content-md-between flex-md-row-reverse">
                <div class="pb-3 pb-md-0 mx-n2">
                  <span v-for="(item, index) in content.navbar_bottom">
                    <span v-if="index !== 0" class="text-gray-200">|</span>
                    <a class="navbar-link mx-2 disclaimer" :href="item.url.url">{{item.url.title}}</a>
                  </span>
                </div>
                <span v-if="content.disclaimer" class="disclaimer">{{content.disclaimer}}</span>
              </div>
              <div class="col-12 col-md-10" v-if="recaptchaIsActive()">
                <span class="disclaimer">
                  This site is protected by reCAPTCHA and the Google
                  <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                  <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </intersect>
  </div>
</template>

<style lang="scss">
[footer-component] {
  background-color: $gray-700;

  a[data-toggle="collapse"] {

    & > i {
      transition: transform .25s;
    }

    &.collapsed > i {
      transform: rotate(180deg);
    }
  }

  .btn {
    width: fit-content;
  }

  #supported-by {
    font-size: .75rem;
    font-weight: 800;
  }

  .social-icon {
    font-size: 1.25rem;
    color: $gray-200;
    transition: color .25s;

    &:hover,
    &:active,
    &:focus {
      color: $gray-300;
    }
  }

  .logo {
    max-width: 6rem;
  }

  .navbar-link {
    font-weight: 300;
    color: $white;
    transition: color $animation-time-fast;

    &:hover {
      color: darken($white, 15%);
    }
  }

  .navbar-link,
  .section-title {
    font-size: .875rem;
  }

  .section-title {
    font-weight: 800;
  }

  .disclaimer {
    font-size: 0.75rem;
    color: $gray-200;
  }

  .box-bg {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  @include media-breakpoint-down(sm) {
    .logo {
      background-size: 80% !important;

      &.background-left {
        background-position: left;
      }

      &.background-right {
        background-position: right;
      }
    }
  }

}

</style>
