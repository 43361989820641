<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  mounted() {
  },
  computed: { },
  methods: { }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" v-if="isContentVisibleToUser()" :style="theme" class="py-5" list-keypoint-component>
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-12 py-4" v-for="keypoint in content.keypoint">
          <img class="pb-4" :src="keypoint.icon.url" :alt="keypoint.icon.alt">
          <h3 class="h2">{{keypoint.title}}</h3>
          <div v-html="keypoint.description"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[list-keypoint-component] {
  background-color: var(--theme-background);
  color: var(--theme-color);

  img {
    height: 80px;
  }
}

</style>
