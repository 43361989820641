<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin],
  computed: {
    spacerClass() {
      return this.content.space ? this.content.space : 'pt-4';
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" 
       v-if="isContentVisibleToUser()" 
       :class="[contentType, spacerClass]"
       :style="{'background-color': content.background_color}"
       stripe-spacer-component>
  </div>
</template>

<style lang="scss">
[stripe-spacer-component] {
}
</style>