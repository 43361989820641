<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data: function () {
    return { 
    };
  },
  created() {
    const theme = this.content.theme || "light";
    if (theme == "light") {
      this.content.background_color = this.content.background_color || "var(--gray-200)";
    } else {
      this.content.background_color = this.content.background_color || "var(--dark)"
    }
  },
  methods: {
    isSafari: Fandom.isSafari,
    isItemActive(item) {
      const re = new RegExp(`^(${item.matching_name})$`);
      return re.test(globalState.pageModel.main_content_name) || item.active;
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" :style="theme" navbar-secondary-component>
    <div class="d-flex w-100" :class="{'navigation-shadow-wrapper': !isSafari()}">
      <div class="navigation-container d-flex w-100">
        <div class="navigation-viewport d-flex mx-auto">
          <div class="text-center py-4 px-4 px-md-5 col-auto" v-for="(item, index) in content.items" :key="`navbar-item-${index}`">
            <a class="nav-link" :class="{'active': isItemActive(item)}" :href="item.url.url">{{item.url.title}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[navbar-secondary-component] {

  background-color: var(--theme-background);

  .navigation-shadow-wrapper {
    position: relative;
  }

  .navigation-container {
    overflow-x: auto;
    position: relative;

    &::-webkit-scrollbar {
      width: 0;  /* Remove scrollbar space */
      background: transparent;  /* Optional: just make scrollbar invisible */
      height: 0;
    }
  }

  .nav-link {
    color: var(--theme-color);
    padding-left: 0;
    padding-right: 0;
    font-size: 1.125rem;

    @include media-breakpoint-up(md) {
      font-size: 1.25rem;
    }

    &:hover, &.active {
      color: $red;
    }
  }

  @include media-breakpoint-down(md) {

    /* side shadows */
    .navigation-shadow-wrapper::after,
    .navigation-shadow-wrapper::before {
      z-index: 1;
      content: '';
      height: 100%;
      width: 3rem;
      position: absolute;
      pointer-events: none;
      background: transparent;
    }


    .navigation-shadow-wrapper::before {
      background: linear-gradient(to left, rgba(255, 255, 255, 0), var(--theme-background) 90%);
      left: 0;
    }

    .navigation-shadow-wrapper::after {
      right: 0;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), var(--theme-background) 90%);
    }
    /* end side shadows */
  }
}
</style>

