<script>
import { trim, isEmpty, isString, isBoolean, map } from "lodash";
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin],
  data: function() {
    return {
      resultContent: null,
      excludeContentIds: [],
      hasMoreChildren: false,
      searchText: "",
      activeSearch: "",
    };
  },
  mounted() {
    Vue.nextTick(() => {    
      const filtersInGet = Fandom.getJSONUrlParameter("filters");
      if (filtersInGet != null) {
        this.searchText = filtersInGet.searchText;
        this.applyFilters();
      }
    });
  },
  computed: {
    filters() {
      return {
        "searchText": this.searchText
      };      
    }
  },
  methods: { 
    getPrimaryButtonClass: Fandom.getPrimaryButtonClass,
    getContentResponsiveImageURL: Fandom.getContentResponsiveImageURL,
    authorText(tileName) {
      const tile = this.getContent(tileName);
      if (tile.alternative_author) {
        return tile.alternative_author;
      } else if (tile.author && Array.isArray(tile.author)) {
        return tile.author.map( a => a.member_name ).join(', ');
      } else if (tile.author && tile.author.member_name) {
        return tile.author.member_name;
      } else {
        return null;
      }
    },
    search() {
      window.location.href = `${window.location.href.split("?")[0]}?filters=${encodeURI(JSON.stringify({"searchText":this.searchText}))}`;
    },
    applyFilters() {
      this.activeSearch = this.searchText;
      this.mayDeleteResultContentChildren();
      this.getSearchableContents(false);
    },
    mayDeleteResultContentChildren() {
      if (Fandom.exists(this.resultContent)) {
        if (Fandom.exists(this.resultContent.children)) {
          for (const chidlName of this.resultContent.children) {
            delete globalState.pageModel.name_to_content[chidlName];
          }  
        }   
        this.resultContent = null;
      }
    },
    getSearchableContents(appendContents) {
      const successBlock = (data) => {
        Vue.set(this, "resultContent", globalState.pageModel.name_to_content[data.main_content_name]);
      }
      const params = this.filtersToGetContentsParams(this.filters);

      Fandom.getSearchableContents(successBlock, params, appendContents);
    },
    appendContents() {
      const successBlock = (data) => {
        this.resultContent = globalState.pageModel.name_to_content[data.main_content_name];
      }
      const params = this.filtersToGetContentsParams(this.filters);
      params["exclude_content_ids"] = map(this.resultContent.children, c => this.getContent(c).id);
      Fandom.getSearchableContents(successBlock, params, true);
    },
    filtersToGetContentsParams(filters) {
      const params = {
        layout: "search-result",
        include_parent_names: true,
        include_children_count: true,
        max_depth: 2,
      };
      if (!!this.content.root_content) {
        params["content_filtering"] = [this.content.root_content];
      }
      if (Fandom.exists(this.resultContent)) {
        params.main_content_name = this.resultContent.name;
      }
      if (Fandom.exists(filters.searchText)) { 
        params.search_text = Fandom.searchTextToBackendFormat(filters.searchText);
      }
      return params;
    },
    redirect(slug) {
      window.location.href = slug;
    },
    itemTitle(item) {
      const content = this.getContent(item);
      return content.seo && content.seo.title ? content.seo.title : content.title;
    } 
  },
};

</script>

<template>
  <div v-easyadmin="easyadminId" class="mh-100vh-header" :class="contentType" v-if="isContentVisibleToUser()" stripe-search-component>
    <div class="heading">
      <div class="container">
        <div class="row">
          <div class="col-md-10 text-left my-5">
            <h3 class="search-heading">{{ft("globals.you_searched_for")}}</h3>
            <input 
              type="text" 
              class="custom-input" 
              v-model="searchText" 
              :placeholder="ft('globals.search')" 
              v-on:keyup.enter="search()"
            >
          </div>
        </div>
      </div>
    </div>
    <div v-if="resultContent">
      <div class="container py-5">
        <div class="results-count">
          {{resultContent.children_count}} {{ft("globals.results_for")}} "{{activeSearch}}"    
        </div>
      </div>
      <div v-for="child in resultContent.children">
        <div class="container child py-4 cursor-pointer d-flex align-items-md-center flex-column flex-md-row" @click="redirect(getContent(child).slug)">
          <div class="bg-cover-center thumbnail mr-4" :style="{'background-image': `url(${getContent(child).image.url})`}" v-if="getContent(child).image"></div>
          <div class="thumbnail d-flex justify-content-center align-items-center bg-gray-300 mr-4 text-gray-500" v-else>
            <i class="fal fa-3x fa-image"></i>
          </div>
          <div>
            <div class="deco  pt-3 mt-1" v-if="!!getDecorator(getContent(child), 'default')">
              {{getDecorator(getContent(child), "default").title}}
            </div>
            <div class="deco text-gray-700 pt-3 mt-1" v-if="!!authorText(child)">
              {{authorText(child)}}
            </div>
            <div class="child-title my-1 py-3" v-if="itemTitle(child)">
              {{itemTitle(child)}}
            </div>
          </div>
        </div>
        <div class="container border-bottom"  v-if="resultContent.children[resultContent.children.length - 1] != child"></div>
      </div>
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center container py-5" v-if="resultContent && resultContent.has_more_children"> 
      <button 
        class="btn shadow-none" 
        :class="getPrimaryButtonClass()"
        @click="appendContents()"
      >{{ft("globals.load_more")}}</button>
    </div>
  </div>
</template>

<style lang="scss">
[stripe-search-component] {  
  .heading {
    background-color: $gray-200;
  }

  .search-heading {
    font-size: 1.5rem;
  }

  .results-count {
    color: $gray-400;
    font-size: $font-size-xs;
    text-transform: uppercase;
  }

  .deco {
    font-size: $font-size-xs;
    text-transform: uppercase;
    color: $primary;
    font-weight: 400;
  }

  .child:hover {
    .child-title {
      color: $gray-400;
    }
  }

  .child-title {
    font-size: 1.25rem;
    transition: color .25s;
  }

  .custom-input {
    height: 3.75rem;
    margin-left: 0;
    padding: 0;
    font-size: 2.25rem;
    line-height: 2.875em;
    text-transform: uppercase;
    font-weight: 600;
    color: $dark;
    background-color: transparent;
    border: 0;
    outline-width: 0px;

    &::placeholder {
      color: $gray-400;
    }
  }

  .thumbnail {
    height: 6.25rem;
    width: 9.375rem;
  }

  .breadcrumb {
    *, .breadcrumb-item > a, .breadcrumb-item + .breadcrumb-item::before {
      color: $dark !important;
      font-weight: 700;
      opacity: .5;
    }

    .breadcrumb-item:last-child {
      font-weight: 700;
      color: $dark !important;
    }
  }
}
</style>