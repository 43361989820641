<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  props: {
    alternativeAppendContents: Function,
    optContentName: String
  },
  methods: {
    appendContents() {
      const count = get(this.content, "contents_count", null);
      if (Fandom.exists(this.alternativeAppendContents)) {
        this.alternativeAppendContents(true);
      } else {
        Fandom.appendContents(this.content.name, null, count);
      }
    },
    childLayout(child) {
      return this.getContent(child).layout;
    }
  },
  computed: {
    gridColumnSet() {
      const columnSet = {
        "1": "col-lg-4 col-md-6 col-12 p-sm-1 px-3 ",
        "2": "col-xl-2 col-lg-3 col-md-4 col-6 p-1"
      }

      return columnSet[this.content.skin || "1"];
    },
    gridRowSet() {
      const rowSet = {
        "1": "mx-sm-n1",
        "2": "mx-n1"
      }

      return rowSet[this.content.skin || "1"];
    },
    aspectRatio() {
      return get(this.content, "aspect_ratio", "ar-16by9")
    }
  },
};

</script>

<template>
  <div 
    v-easyadmin="optContentName ? optContentName : easyadminId" 
    v-if="isContentVisibleToUser()" 
    :class="[contentType]" 
    :style="theme" 
    class="py-4"
    grid-fixed-component
  >
    <stripe-header :content="content"></stripe-header>
    <div class="container-fluid">
      <div v-if="!content.children || content.children.length == 0" class="w-100 d-flex justify-content-center">
        <p class="text-uppercase text-muted">{{ft("globals.no_content")}}</p>
      </div>
      <div class="row" v-else>
        <div class="col-12">
          <div class="row" :class="gridRowSet">
            <div class="py-1" :class="gridColumnSet" v-for="(child, index) in content.children" :key="`tile-${index}`">
              <div class="w-100 position-relative" :class="aspectRatio">
                <component :is="childLayout(child)" :content="getContent(child)"></component>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="content.has_more_children" class="container my-4">
      <div class="row">
        <div class="col-sm-12 text-center">
          <button class="btn shadow-none" :class="themeButtonClass" @click="appendContents()">{{ft("globals.load_more")}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[grid-fixed-component] {
  background-color: var(--theme-background);
  color: var(--theme-color);
}
</style>
