<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { sendFandomAnalyticsEvent } from "src/modules/fandom_analytics_module.js";
import { get } from 'lodash';

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
    }
  },
  methods: {
    getContentURL: Fandom.getContentURL,
    getBackgroundUrl: Fandom.getBackgroundUrl,
    getTileThumbnailUrl: Fandom.getTileThumbnailUrl,
    clickHandler(event) {
      const target = $(event.currentTarget);
      const href = target.attr("href");

      if (href == "javascript:void(0)") {
        return false;
      }

      if (target.attr("target") == "_blank") {
        window.open(href, '_blank');
      } else {
        window.location.href = href;
      }

      sendFandomAnalyticsEvent("Interaction", "Grid Tile Click", this.containerContent.name);
      return false;
    },
  },
  computed: {
    contentUrl() {
      const result = this.getContentURL(this.content);
      return result || "javascript:void(0)";
    },
    category() {
      return this.decorator ? this.decorator.title : this.content.category;
    }
  }
};
</script>

<template>
  <div 
    v-easyadmin="easyadminId" 
    :class="contentType"
    class="position-absolute-center bg-transition text-white" 
    tile-vertical-component
  >
    <a :href="contentUrl" 
       :target="getTarget(content.url)" 
       @click.prevent="clickHandler($event)"
    >
      <div class="bg-cover-center position-absolute-center bg" :style="{'background-image': getTileThumbnailUrl()}"></div>
      <div class="position-absolute-center linear-gradient-y" v-if="!content.hide_gradient"></div>
      <div class="position-absolute-center d-flex justify-content-between flex-column">
      	<div class="text-white m-4 text-center font-weight-bold text-uppercase">{{category}}</div>
        <div class="icon-container w-75 mx-auto" v-if="content.icon">
          <div :style="{'background-image': getBackgroundUrl(content.icon.url)}" class="icon ar-1by1 w-100"></div>
        </div>
      </div>
    </a>
  </div>
</template>

<style lang="scss" scoped>
[tile-vertical-component] {

  .icon {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    z-index: 1;
  }

  .bg {
    transition-duration: 3s;
    transition-timing-function: cubic-bezier(0.23,1,0.32,1);
  }
}
</style>
