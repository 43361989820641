<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      userInfo: null,
      avatar: false,
      background_image: false,
      loading: false
    };
  },
  mounted() {
    this.getUserInfo();
  },
  computed: {
    componentStyle() {
      return {
        "background-image": this.background_image ? Fandom.getBackgroundUrl(this.background_image) : null
      };
    }
  },
  methods: {
    getUserMainRewardCount: Fandom.getUserMainRewardCount,
    getBackgroundUrl: Fandom.getBackgroundUrl,
    getUserAvatar() {
      const anonymousAvatar = get(this.globalState.pageModel, ["layout", "content", "anonymous_avatar", "url"], false);
      return get(this.userInfo, "avatar", anonymousAvatar);
    },
    openModal() {
      $(this.$refs["edit-pictures"]).modal("show");
    },
    getCountableRewardCounter(countableRewardName) {
      return get(this.userInfo, ["reward_name_to_counter", countableRewardName], null);
    },
    updateUserImage(image) {
      const file = this.$refs[image].files[0];
      const filesData = new FormData();
      filesData.append("file", file);
      this.loading = true;
      Fandom.ajax({
        method: "POST",
        data: filesData,
        processData: false,
        contentType: false,
        url: `/api/v5/update_user_picture/${image}`,
        success: (data) => {
          if(Fandom.exists(data.new_image)) {
            // Success
            this[image] = Fandom.expandUrl(data.new_image.url);
          }
          if(this.errors) {
            // Errors
          }
          this.loading = false;
        }
      });
    },
    getUserInfo() {
      let publicId = Fandom.getUrlParameter("public_id");
      Fandom.ajax({
        method: "GET",
        data: {
          container_content_id: this.containerContent.id,
          content_id: this.content.$id, // it could be nil in the case of non-record content
          public_id: publicId
        },
        url: "/api/v5/get_user_info",
        success: (data) => {
          this.userInfo = data;
          this.avatar = this.getUserAvatar();
          this.background_image = get(this.userInfo, "background_image", get(this.content, ["background_image", "url"], false));
        }
      });
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" class="bg-cover-center bg-gray-900 text-white" :style="componentStyle" user-dashboard-component>
    <div class="container" v-if="userInfo">
      <div class="row user-dashboard-content align-items-center justify-content-center">
        <div class="col-12 col-md-8 my-4 d-flex align-items-center">
          <div class="mb-0 z2">
            <div
              class="bg-cover-center user-dashboard-avatar position-relative rounded-circle cursor-pointer"
              :style="{'background-image': avatar ? getBackgroundUrl(avatar) : null }"
              @click="openModal"
              >
              <label for="user-avatar" class="position-absolute-center d-flex align-items-center justify-content-center edit-icon cursor-pointer mb-0" :class="{'has-avatar': avatar}">
                <i class="far fa-pencil"></i>
              </label>
              <div v-if="!avatar" class="position-absolute-center d-flex align-items-center justify-content-center user-icon">
                <i class="far fa-user"></i>
              </div>
            </div>
          </div>
          <div class="mt-0 ml-4 z2">
            <div class="font-weight-normal h1">Ciao,</div>
            <div class="h1 m-0 p-0 dashboard-title">{{userInfo.first_name}} {{userInfo.last_name}}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="user-dashboard-content"></div>
    <div class="d-none">
      <input type="file" ref="avatar" id="user-avatar" @change="updateUserImage('avatar')">
      <input type="file" ref="background_image" id="user-background-image" @change="updateUserImage('background_image')">
    </div>
  </div>
</template>

<style lang="scss" scoped>
[user-dashboard-component] {
  $title-contrast-size: .5px;
  $title-contrast-color: rgba($white, .75);

  .user-dashboard-content {
    height: $user-dashboard-height;
  }

  .z2 {
    z-index: 2;
  }

  .user-dashboard-avatar {
    width: 8rem;
    height: 8rem;
    font-size: 2rem;
    overflow: hidden;
    color: $user-dashboard-avatar-color;
    background-color: $user-dashboard-avatar-bg-color;

    .edit-icon {
      opacity: 0;
      transition: opacity .25s ease-in-out;

      &.has-avatar {
        background-color: rgba($black, .3);
      }
    }

    &:hover {
      .user-icon {
        opacity: 0;
      }

      .edit-icon {
        opacity: 1;
      }
    }
  }

  .user-icon {
    pointer-events: none;
  }
}
</style>
