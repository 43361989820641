<script>
import { get } from "lodash";
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      isSupportContentActive: false,
      searchBar: false,
      searchText: ""
    };
  },
  methods: {
    isItemActive(item) {
      // When url is a ContentLink extra field type the content name can be used as matching 
      // name when mathing name is missing. 
      if (item.url.content && !item.matching_name) {
        item.matching_name = item.url.content;
      }
      const re = new RegExp(`^(${item.matching_name})$`);
      return re.test(globalState.pageModel.main_content_name);
    },
    isComingSoong(item) {
      return item.url.url == "$empty";
    },
    search() {
      window.location.href = `${this.resultUrl}?filters=${encodeURI(JSON.stringify({"searchText":this.searchText}))}`;
    }
  },
  computed: { 
    resultUrl() {
      return get(this.content, ['search_result_url','url'], "search");
    },
    isUserRegistered() {
      return Fandom.isUserRegistered();
    },
    username() {
      const user = globalState.pageModel.user;
      return `${user.first_name} ${user.last_name.charAt(0)}.`;
    },
    items() {
      return get(globalState, ["pageModel", "layout", "header", "navbar_items"], []); 
    },
    logoAlt() {
      return get(this.pageModel, ['seo', 'title'], '');
    },
    profileUrl() {
      return get(this.content, ['profile_url', 'url'], false);
    }
  }
};
</script>

<template>
  <nav class="navbar navbar-expand-lg fixed-top justify-content-between align-items-center bg-dark px-2" navbar-component>
    <a v-if="content.secondary_logo" :href="content.secondary_logo_url.url" target="_blank" class="mx-3">
      <img :src="content.secondary_logo.url" :alt="content.secondary_logo.alt" class="secondary-navbar-logo"/>
    </a>
    <a :href="applyContextToUrl('/')" class="mx-3">
      <h1 class="mb-0">
        <img :src="content.logo.url" :alt="content.logo.alt" class="navbar-logo"/>
      </h1>
    </a>
    <div class="d-flex">
      <div class="d-sm-flex d-none align-items-center">
        <div class="nav-item px-3">
          <a v-if="!isUserRegistered" :href="applyContextToUrl('/users/sign_up')">{{ft('globals.navbar.sign_in')}}</a>
          <div v-else class="text-white text-right">
            <a v-if="!!profileUrl" :href="profileUrl" :target="getTarget(content.profile_url)" class="font-weight-bold">{{username}}</a>
            <span v-else class="font-weight-bold">{{username}}</span>
            <br>
            <a class="font-weight-normal" :href="applyContextToUrl('/users/sign_out')">{{ft('globals.navbar.sign_out')}}</a>
          </div>
        </div>
      </div>
      <div class="search-icon cursor-pointer d-flex align-items-center px-2" @click="searchBar = true">
        <i class="far fa-search"></i>
      </div>
      <button class="navbar-toggler d-block text-white" type="button" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" @click="isSupportContentActive = !isSupportContentActive">
        <i v-if="!isSupportContentActive" class="fal fa-bars"></i>
        <i class="fal fa-times" v-else></i>
      </button>
    </div>
    <transition 
      enter-active-class="animated faster slideInRight" 
      leave-active-class="animated faster slideOutRight" 
    >
      <div class="position-absolute-center bg-dark search-bar d-flex px-4 align-items-center" v-if="searchBar">
        <div class="input-group mx-3">
          <input type="text" class="form-control" v-on:keyup.enter="search()" :placeholder="ft('globals.search')" v-model="searchText">
        </div>
        <div class="search-icon cursor-pointer d-flex align-items-center mx-2" @click="search()">
          <i class="far fa-search"></i>
        </div>
        <div class="search-icon cursor-pointer d-flex align-items-center mx-2" @click="searchBar = false">
          <i class="far fa-times"></i>
        </div>
      </div>
    </transition>
    <transition 
      enter-active-class="animated faster slideInDown" 
      leave-active-class="animated faster slideOutUp" 
    >
      <div id="navbarSupportedContent" class="navbar__support-content bg-dark" v-show="isSupportContentActive">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <ul class="list-unstyled">
                <li class="py-1" v-for="(item, index) in items" :key="`item-${index}`">
                  <a class="text-white h2 font-weight-normal" :class="{'text-muted': isComingSoong(item)}" :href="isComingSoong(item) ? 'javascript: void(0)' : item.url.url" :target="getTarget(item.url)">{{item.url.title}}</a>
                </li>
              </ul>
              <hr class="text-white bg-light d-block d-sm-none">
              <div class="d-flex d-sm-none">
                <div class="nav-item">
                  <a v-if="!isUserRegistered" :href="applyContextToUrl('/users/sign_up')">{{ft('globals.navbar.sign_in')}}</a>
                  <div v-else class="text-white">
                    <a v-if="!!profileUrl" :href="profileUrl" :target="getTarget(content.profile_url)" class="font-weight-bold">{{username}}</a>
                    <span v-else class="font-weight-bold">{{username}}</span>
                    <br>
                    <a class="font-weight-normal" :href="applyContextToUrl('/users/sign_out')">{{ft('globals.navbar.sign_out')}}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </nav>
</template>

<style lang="scss">
[navbar-component] {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  height: $header-height;

  @include media-breakpoint-down(md) {
    height: $header-height-sm;
  }

  .navbar-toggler {
    z-index: 1050;
    width: 3rem;
    text-align: center;
  }

  .navbar__support-content {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    padding-top: $header-height-sm;
  }

  .navbar-logo {
    height: 2.8rem;
    width: auto;
  }

  .secondary-navbar-logo {
    width: 2.7rem;
    height: auto;
  }

  .search-icon {
    transition: color .25s;
    font-size: 1.25rem;
    color: white;

    &:hover {
      color: darken($white, 10);
    }
  }

  .search-bar {
    z-index: 1051;

    // .search-icon {
    //   color: $dark;
    // }

    input {
      height: auto !important;
      padding: .5rem 1rem;
      background-color: $gray-900;
      border: 0;  
    }
  }

  .nav-item {
    text-transform: uppercase;
    font-size: .75rem !important;
    letter-spacing: 1px;

    a {
      color: $gray-300;
      text-decoration: none;
      transition: color .25s;
      font-weight: bold;
    }

    &:hover,
    &:active,
    &:focus,
    &.active {
      a {
        color: $white;
      }
    }
  }
}
</style>