<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data: function() {
    return {
      filteringTag: null,
      loading: false
    };
  },
  mounted() {},
  methods: {
    toggleFilter(item) {
      if (Fandom.exists(item)) {
        this.activeItem = this.getContent(item.filter).title;
        this.filteringTag = item.filter;
      } else {
        this.activeItem = this.ft("globals.all");
        this.filteringTag = this.content.filters_content;
      }
      this.loadContents(false);
    },
    loadContents(appendContents) {
      let successBlock = null;
      const params = {}

      if (!appendContents) {
        this.loading = true;
        successBlock = () => {
          this.loading = false;
        }
      }

      if (appendContents) {
        params['exclude_content_ids'] = this.content.children.map(child => this.getContent(child).id);
      }

      if (this.filteringTag) {
        params['content_filtering'] = [this.filteringTag];
      }

      Fandom.getContents(this.content.name, params, successBlock, appendContents);
    }
  },
  computed: {
    activeFilterTitle() {
      return !this.filteringTag ? this.ft('ecommerce.all') : this.getContent(this.filteringTag).title;
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" v-if="isContentVisibleToUser()" :class="contentType" class="py-4" :style="theme" course-grid-component>
    <!-- filter navbar mobile -->
    <nav class="navbar navbar-expand-md navbar-light d-md-none mx-3 border bg-white p-0" v-if="content.filters && content.filters.length > 0">
      <a data-toggle="collapse" class="collapsed d-flex align-items-center justify-content-between w-100 bg-gray-800 shadow-none" href="#collapsibleNavbar">
        <span class="navbar-brand mx-3 text-uppercase text-white" href="#">
          <i class="fas mr-2" :class="!filteringTag ? 'fa-th' : getContent(filteringTag).icon"></i>
          {{activeFilterTitle}}
        </span>
        <div class="navbar-toggler mx-3 px-0 d-flex align-items-center">
          <i class="fal fa-chevron-up text-white"></i>
        </div>
      </a>
      <div class="collapse navbar-collapse" id="collapsibleNavbar">
        <div class="py-2 px-3 border-top">
          <ul class="navbar-nav">
            <li class="nav-item" :class="{'d-none': !filteringTag}">
              <a 
                class="nav-link text-uppercase" 
                href="javascript:void(0)"
                @click="toggleFilter()"
                :class="{'active': !filteringTag}"
              ><i class="fas fa-th mr-2"></i>{{ft("ecommerce.all")}}</a>
            </li>
            <li class="nav-item" :class="{'d-none': filter.filter == filteringTag}" v-for="filter in content.filters">
              <a 
                class="nav-link text-uppercase" 
                href="javascript:void(0)"
                @click="toggleFilter(filter)"
                :class="{'active': filter.filter == filteringTag}"
              ><i class="far mr-2" :class="getContent(filter.filter).icon" v-if="getContent(filter.filter).icon"></i>{{getContent(filter.filter).title}}</a>
            </li>
          </ul>
        </div>
      </div> 
    </nav>
    <!-- filter buttons for desktop -->
    <div class="container text-center d-none d-md-block" v-if="content.filters && content.filters.length > 0"> 
      <div class="btn-group my-3" role="group">
        <button 
          class="btn btn-outline-gray-800 shadow-none"
          @click="toggleFilter()"
          :class="{'active': !filteringTag}"
        >
          <i class="fas fa-th mr-2"></i>
          {{ft("ecommerce.all")}}
        </button>
        <button 
          v-for="filter in content.filters" 
          class="btn btn-outline-gray-800 shadow-none"
          @click="toggleFilter(filter)"
          :class="{'active': filter.filter == filteringTag}"
        >
          <i class="far mr-2" :class="getContent(filter.filter).icon" v-if="getContent(filter.filter).icon"></i>
          {{getContent(filter.filter).title}}
        </button>
      </div>
    </div>
    <div class="container" v-if="!loading">
      <course-tile v-for="(child, index) in content.children" :key="`tile-${index}`" class="tile-container my-4" :content="getContent(child)"></course-tile>
    </div>
    <div class="container text-center py-5" v-else>
      Loading...
    </div>
    <div class="container text-center py-5" v-if="content.has_more_children">
      <button class="btn" :class="themeButtonClass" @click="loadContents(true)">{{ft('globals.load_more')}}</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[course-grid-component] {
  background-color: var(--theme-background);

  .btn-group .btn {
    border-radius: 0;
  }

  .navbar-toggler {
    font-size: .875rem;
    transition: transform .25s;
    height: 4rem;
    border: none;

    &:focus {
      outline: 0px;
    }
  }

  .collapsed .navbar-toggler {
    transform: rotate(180deg);
  }

  .navbar-brand {
    font-size: 1rem;
    font-weight: 600;
  }
}
</style>