<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin],
  data: function () {
    return {
      overvideoInteraction: null,
      videoPlayer: {}
    };
  },
  computed: {
    isHeadingPresent() {
      return this.content.subtitle || this.content.description;
    },
  },
  methods: {
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" course-product-video-chapter-component>
    <div class="video-section position-relative bg-dark">
      <component
        ref="embed-video" 
        class="embed-responsive embed-responsive-16by9" 
        :is="`embed-${content.play.media_type}`"  
        :content="content" 
        :opt-container-content="containerContent"
        @video-player-handler="videoPlayer = arguments[0]"
        @overvideo-interaction-handler="overvideoInteraction = arguments[0]"
        >
      </component>  
      <transition enter-active-class="animated fast fadeIn" leave-active-class="animated fast fadeOut">
        <course-product-video-chapter-trivia-interaction 
          v-if="overvideoInteraction"
          :key="`course-product-video-chapter-trivia-interaction-${overvideoInteraction.interaction.id}`"
          :content="overvideoInteraction" 
          :opt-container-content="containerContent"
          @interaction-done="$refs['embed-video'].unsetOvervideoInteraction()">
        </course-product-video-chapter-trivia-interaction>
      </transition>
      <div class="d-flex justify-content-center align-items-center flex-column py-4 text-center bg-light position-absolute-center" v-if="videoPlayer.end">
        <course-countdown
          :content="content"
          :opt-container-content="containerContent"
          :initalCountdown="3"
          @next-bookmark="$emit('next-bookmark');"
        >  
        </course-countdown>
      </div>
    </div>
    <div class="row mx-0 justify-content-center bg-gray-200 py-5" v-if="isHeadingPresent">
      <div class="col-12 col-lg-8">
        <h3 class="h2" v-if="content.subtitle">{{content.subtitle}}</h3>
        <div v-html="content.description" v-if="content.description"></div>
      </div>
    </div>
    <course-download-additional-resources
      v-if="content.additional_resources"
      :content="content"
      :opt-container-content="containerContent"
    >
    </course-download-additional-resources>
	</div>
</template>

<style lang="scss">
[course-product-video-chapter-component] { 
}
</style>