<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  mounted: function() {},
  methods: {
    authorContent(authors) {
      const result = { items: [], padding_top: 0 };
      authors.forEach(author => {
        result.items.push({
          $id: author.$id,
          bio: author.member_bio,
          name: author.member_name,
          photo: author.member_photo,
          role: author.member_role
        });
      });
      return result;
    }
  },
  computed: {
    authors() {
      if (!this.content.author) {
        return [];
      } else if (Array.isArray(this.content.author)) {
        return this.content.author;
      } else {
        return [this.content.author];
      }
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" v-if="isContentVisibleToUser()" :class="contentType" course-product-streaming-component>
    <div class="bg-dark" v-if="content.embed_media || exists(content.play) || exists(content.image)">
      <div class="container">
        <div class="row">
          <div class="col-12 p-0">
            <div v-if="content.embed_media" v-html="content.embed_media">
            </div>
            <component 
              v-else-if="exists(content.play)" 
              class="embed-responsive embed-responsive-16by9" 
              :is="`embed-${content.play.media_type}`"  
              :content="content" 
              :opt-container-content="containerContent">
            </component>  
            <div v-else-if="content.image" class="bg-cover-center ar-16by9 w-100" :style="{'background-image': `url(${content.image.url})`}"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="container py-5" v-if="content.title || content.description">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-8">
          <h2 class="h1" v-if="content.title">{{content.title}}</h2>
          <div v-html="content.description" v-if="content.description"></div>
        </div>
      </div>
    </div>
    <list-people v-if="authors.length > 0" :content="authorContent(authors)" :opt-container-content="containerContent" component-class="w-100"></list-people>
    <div v-if="content.stripes">
      <component v-for="stripe in content.stripes" :is="stripe.layout || div" :content="stripe" :opt-container-content="containerContent" :key="`${content.name}-${stripe.name}`"></component>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>