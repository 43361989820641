<template>
  <div v-easyadmin="easyadminId" v-if="isContentVisibleToUser()" class="themed-item text-center" :class="[contentType, paddingY]"  :style="theme" grid-podcast-component>
    <div class="container">
      <h2 class="h1" v-if="content.title">{{content.title}}</h2>
      <div v-if="content.children && content.children.length == 0" class="row">
        <div class="col-12"><p>{{ft("globals.no_content")}}</p></div>
      </div>
      <div class="row" v-else>
        <div v-for="(child, index) in content.children" class="col-12" :class="{'mt-3': index > 0}" :key="`child-${index}`">
          <tile-podcast :content="getContent(child)"></tile-podcast>
        </div>
      </div>
      <div class="row my-3" v-if="content.has_more_children">
        <div class="col-12 d-flex justify-content-center">
          <button class="btn" :class="themeButtonClass" @click="appendContents()">{{ft("globals.load_more")}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data() {
    return {
      paddingYDefault: 4,
      getContentsParams: {
        limit: (this.content.limit || 8),
        content_filtering: []
      }
    };
  }, 
  methods: {
    appendContents() {
      Fandom.appendContents(this.content.name, null, null, this.getContentsParams);
    },
  }
};
</script>