<script>
import { get } from "lodash";
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  computed: {
    url() {
      return get(this.content, ['url','url'], Fandom.getContentURL(this.content));
    }
  }
};
</script>

<template>
  <div class="row my-1 text-left" v-easyadmin="easyadminId" :class="contentType" tile-podcast-component>
    <div class="col-12 col-md-10 offset-md-1">
      <a :href="url" class="text-white text-decoration-none">
        <div class="media bg-gray-800 h-100">
          <div class="media-img-container position-relative">
            <img :src="getContentResponsiveImage('thumbnail')">
          </div>
          <div :style="{'color': content.text_color}" class="media-body d-flex flex-column justify-content-center h-100 p-4 p-xl-5">
            <h3 class="h1 mb-0">{{content.title}}</h3>
            <div v-html="content.description"></div>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.media {
  cursor: pointer;

  img {
    max-width: 200px;
  }

  .layer {
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

@include media-breakpoint-down(sm) {
  .media {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
    }
  }
}
</style>
