<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default {
  mixins: [fandomBasicMixin],
  data: function () {
    return {
    	bookmark: this.content.chapters[0].chapters[0],
      showSidebar: false,
      unitNumber: 1,
    };
  },
  mounted() {
  },
  methods: {
    authorContent(author) {
      return {
        $id: author.$id,
        members: [author]
      }
    },
  	setBookmark(data) {
  		this.bookmark = data.chapter;
      this.unitNumber = data.unitNumber;
      if (this.isViewportSmall && this.showSidebar) {
        this.toggleSidebar();
      }
  	},
    toggleSidebar() {
      if (this.isViewportSmall && !this.showSidebar) {
        this.showSidebar = true;
        Vue.nextTick(() => {
          disableBodyScroll(this.$refs["mobile-sidebar"]);
        });
      } else {
        this.showSidebar = false;
        clearAllBodyScrollLocks();      
      }
    },
    goToNextBookmark() {
      console.log(this.$refs);
      const unit = this.content.chapters[this.unitNumber - 1];

      const activeIndex = unit.chapters.findIndex(chapter => {
        return chapter.$id === this.bookmark.$id;
      });
      if (activeIndex < (unit.chapters.length - 1)) {
        this.bookmark = unit.chapters[activeIndex + 1];
      } else if (this.unitNumber < this.content.chapters.length) {
        this.bookmark = this.content.chapters[this.unitNumber].chapters[0];
        this.unitNumber += 1;
        this.$refs["course-sidebar"].unitNumber += 1;
      } else {
        /* do nothing */
      }

      // Update course-sidebar component.
      this.$refs["course-sidebar"].bookmark = this.bookmark;
    },
    lastBookmark() {
      const unit = this.content.chapters[this.unitNumber - 1];
      const activeIndex = unit.chapters.findIndex(chapter => {
        return chapter.$id === this.bookmark.$id;
      });
      return this.unitNumber === this.content.chapters.length && activeIndex === (unit.chapters.length - 1);
    },
    authorContent(author) {
      return {
        $id: author.$id,
        members: [author]
      }
    }
  },
  computed: {
    isViewportSmall() {
      return globalState.viewport.sm || globalState.viewport.xs || globalState.viewport.md;
    },
    authors() {
      if (!this.content.author) {
        return [];
      } else if (Array.isArray(this.content.author)) {
        return this.content.author;
      } else {
        return [this.content.author];
      }
    },
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" course-product-component>
		<div class="container-fluid">
			<div class="row">
				<div class="col-lg-3 px-0 flex-grow-1 d-none d-lg-block">
			    <course-sidebar ref="course-sidebar" :propBookmark="bookmark" :content="content" v-on:set-bookmark="setBookmark"></course-sidebar>
				</div>
				<div class="col-lg-9 px-0">
					<div
            v-if="bookmark.layout"
            :is="bookmark.layout"
            v-on:next-bookmark="goToNextBookmark"
            :content="bookmark"
            :lastBookmark="lastBookmark()"
            :opt-container-content="containerContent"
            :animation="false"
            :unitNumber="unitNumber"
            :key="bookmark.$id"
            bookmark
          ></div>
          <div class="py-4" v-if="content.description || content.author || authors.length > 0">
  					<div class="row justify-content-center mx-0" v-if="content.description || content.author">
              <div class="col-12 col-lg-8 py-4">
                <div v-html="content.description"></div>
              </div>
            </div>
            <list-people v-for="(author, index) in authors" :key="`author-${index}`" :content="authorContent(author)" :opt-container-content="containerContent" component-class="w-100"></list-people>
          </div>
				</div>
			</div>
		</div>
    <transition enter-active-class="animated faster fadeIn" leave-active-class="animated faster fadeOut">
      <div class="position-fixed course-product__sidebar" v-if="isViewportSmall && showSidebar"></div>
    </transition>
    <transition 
      enter-active-class="animated faster slideInLeft" 
      leave-active-class="animated faster slideOutLeft"
    >
      <div class="sidebar-container" v-if="isViewportSmall" v-show="showSidebar" ref="mobile-sidebar">
        <course-sidebar ref="course-sidebar" :content="content" :propBookmark="bookmark" @set-bookmark="setBookmark" @toggle-sidebar="toggleSidebar()"></course-sidebar>
      </div>
    </transition>
    <button class="btn btn-gray-800 d-lg-none show-sidebar px-3" @click="toggleSidebar()">
      <i class="far fa-list mr-2"></i>{{ft('ecommerce.content')}}
    </button>
  </div>
</template>

<style lang="scss">
[course-product-component] {

  [table-people-component] {
    // prevent missing table-people-component component class padding
    padding-left: 15px;
    padding-right: 15px;
  }

  [bookmark] .container {
    width: 100% !important;
  }

  .show-sidebar {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    z-index: 1;
    border-radius: 0;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  }

  .sidebar-container {
    position: fixed;
    width: 40%;
    top: 0;
    bottom: 0;
    left: 0;

    @include media-breakpoint-down(md) {
      width: 80%;
      overflow-y: auto;
    }
  }

  .sidebar-container, .course-product__sidebar {
    z-index: $zindex-youtube-popup;
  }

  .course-product__sidebar {
    background-color: rgba($gray-200, .5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

}
</style>