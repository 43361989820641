<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  mounted() { },
  computed: {
    inheritContent() {
      return Fandom.getContent(this.content.url.content);
    },
    contentUrl() {
      return Fandom.getContentURL(this.content);
    },
    category() {
      const decoratorCategory = Fandom.exists(this.decorator) ? this.decorator.title : null;
      return decoratorCategory || this.content.category;
    },
    authorText() {
      if (this.content.alternative_author) {
        return this.content.alternative_author;
      } else if (this.content.author && Array.isArray(this.content.author)) {
        return this.content.author.map( a => a.member_name ).join(', ');
      } else if (this.content.author && this.content.author.member_name) {
        return this.content.author.member_name;
      } else {
        return null;
      }
    }
  }
};
</script>

<template>
  <div>
    <a :href="contentUrl">
      <div v-easyadmin="easyadminId" v-if="isContentVisibleToUser()" :class="contentType" class="row h-100  flex-lg-row-reverse mx-0" :style="theme" course-tile-component>
        <div class="col-12 col-lg-6 px-0 bg-dark bg-transition">
          <div class="ar-16by10 bg-cover-center w-100 h-100 bg" :style="{'background-image': getContentResponsiveImageURL('image')}"></div>
        </div>
        <div class="col-12 col-lg-6 p-4 d-flex flex-column justify-content-between">
          <div class="pb-3 pb-sm-0">
            <div v-if="category" class="course-tile__category d-inline-block mb-3 text-uppercase"><small>{{category}}</small></div>
            <div v-if="content.title || (content.author && content.author.member_name)" class="mb-3">
              <h3 v-if="content.title" class="h1 mb-0">{{content.title}}</h3>
              <h4 v-if="authorText" class="mb-0 font-weight-normal font-italic">{{authorText}}</h4>
            </div>
            <p class="d-none d-sm-block" v-if="content.subtitle">{{content.subtitle}}</p>
          </div>
          <div>
            <a :href="contentUrl" class="btn btn-primary text-uppercase">
              {{content.alternative_registration_button || ft('ecommerce.go_to_course')}} 
            </a>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<style lang="scss" scoped>
[course-tile-component] {
  color: var(--theme-color);

  a:not(.btn) {
    color: var(--theme-color) !important;
  }

  .ar-16by10 {
    padding-bottom: 62.5%;
  }

  background-color: $gray-800;

  .course-tile__category {
    text-transform: uppercase;
  }


}
</style>