<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { interactionMixin } from "src/modules/interactions_module.js";
import Flickity from "flickity";

export default {
  mixins: [fandomBasicMixin, interactionMixin],
  props: {
    lastBookmark: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      carousel: null,
      activeContent: null,
      activeContentAlreadyDoneTimeout: null
    };
  },
  mounted() {
    Vue.nextTick(() => {
      this.carousel = new Flickity(this.$refs["trivia-carousel"], {
        pageDots: false,
        prevNextButtons: false,
        draggable: false,
        adaptiveHeight: false,
        on: {
          ready: () => {
            this.activeContent = this.content.trivia_carousel[0];
          },
          change: (index) => {
            this.activeContent = this.content.trivia_carousel[index];
          }
        }
      });
    })
  },
  computed: {
    getOutlineButtonClass() {
      return this.content.button_color ? `btn-outline-${this.content.button_color}` : "btn-outline-dark";
    },
    showNavigation() {
      return Fandom.exists(this.carousel) && 
             (this.carousel.selectedIndex != this.carousel.cells.length - 1) &&
             (this.carousel.cells.length > 2);
    },
    isThisInteractionFeedbackPhase() {
      return this.activeContent && this.isInteractionFeedbackPhase(this.containerContent, this.activeContent.interaction);
    },
    lockNextSlide() {
      let lockNextSlide = false;
      if (this.activeContent && this.activeContent.interaction) {
        if (!this.isInteractionDone(this.containerContent, this.activeContent.interaction)) {
          lockNextSlide = true;
        }
      } else {
        lockNextSlide = false
      }
      return lockNextSlide;
    }
  },
  watch: {
    // The feedback phase is managed by the interaction module
    activeContent(newValue, previousValue) {
      if (this.activeContentAlreadyDoneTimeout) {
        clearTimeout(this.activeContentAlreadyDoneTimeout);
        this.activeContentAlreadyDoneTimeout = null;
      }
      
      if (Fandom.exists(newValue) && this.isInteractionDone(this.containerContent, newValue.interaction)) {
        this.activeContentAlreadyDoneTimeout = setTimeout(() => {
          this.carousel.next();
        }, 3000);
      }
    },

    isThisInteractionFeedbackPhase(newValue, previousValue) {
      if (previousValue && !newValue) {
        this.carousel.next();
      }
    },
  },
  methods: {
    mayGoToNextSlide() {
      if (!this.lockNextSlide){
        this.carousel.next();
      } 
    },
    getFontSizeClass() {
      return this.optContainerContent.big_fonts ? "big-fonts" : "";
    },
    getInteractionFeedbackText() {
      if (this.isAnswerCorrect(this.containerContent, this.activeContent.interaction, this.getUserInteractionAnswerId(this.containerContent, this.activeContent.interaction))) {
        return this.ft("globals.trivia_interaction.feedback_success_text");
      } else {
        return this.ft("globals.trivia_interaction.feedback_wrong_text");
      }
    },
    getAnswerPercentage(answer, content) {
      if (this.isInteractionDone(this.containerContent, content.interaction)) {
        const contentUserInfo = this.getContentUserInfo(this.containerContent);
        let percentage = contentUserInfo.id_to_interaction[content.interaction.id]["id_to_answer"][answer.$id]["percentage"];
        return percentage.toFixed(0);
      }
    },
    getFeedbackClass() {
      if (this.isAnswerCorrect(this.containerContent, this.activeContent.interaction, this.getUserInteractionAnswerId(this.containerContent, this.activeContent.interaction))) {
        return 'bg-success';
      } else {
        return 'bg-danger';
      }
    },
    updateVerusInteractionCustomized(answer, triviaContent) {
      this.activeContent = null;
      this.updateVersusInteraction(this.containerContent, this.content, triviaContent.interaction, answer.$id, () => {}, () => {
        this.activeContent = triviaContent;
      });
    },
    updateTriviaInteractionCustomized(answer, triviaContent) {
      this.activeContent = null;
      this.updateTriviaInteraction(this.containerContent, this.content, triviaContent.interaction, answer.$id, () => {}, () => {
        this.activeContent = triviaContent;
      });
    },
    getAnswerClasses(answer, content) {
      if (this.isInteractionDone(this.containerContent, content.interaction) && 
          content.interaction.type == "Trivia") {
        if (this.isAnswerCorrect(this.containerContent, content.interaction, answer.$id)) {
          return `${this.getOutlineButtonClass} correct-answer`;
        } else {
          const userInteraction = this.getUserInteraction(this.containerContent, content.interaction);
          const userInteractionAnswerId = Fandom.exists(userInteraction.answer_id) ? userInteraction.answer_id : parseInt(userInteraction.data.answer_id);
          return userInteractionAnswerId == answer.$id ? `${this.getOutlineButtonClass} wrong-answer` : `${this.getOutlineButtonClass}`;
        }
      } else {
        return `${this.getOutlineButtonClass}`;
      }
    },
    isUserAnswer(answerId, content) {
      return this.getUserInteractionAnswerId(this.containerContent, content.interaction) == answerId;
    },
    getOptContentResponsiveImageURL: Fandom.getOptContentResponsiveImageURL
    
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" course-product-trivia-carousel-component>
    <div class="bg-light">
			<div class="row mx-0">
				<div class="col-12 px-0 position-relative background-centered" :style="{'background-image': getOptContentResponsiveImageURL('background_image') }">
          <div v-if="showNavigation" @click="carousel.previous()" class="navigation__prev" :class="{'disabled': carousel.selectedIndex == 0}">
            <i class="fas fa-arrow-left trivia-carousel__navigation-icon"></i>
          </div>
          <div v-if="showNavigation" @click="mayGoToNextSlide()" :class="{'disabled': lockNextSlide}" class="navigation__next">
            <i class="fas fa-arrow-right trivia-carousel__navigation-icon"></i>
          </div>
          <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
            <div v-if="isThisInteractionFeedbackPhase && activeContent.interaction.type == 'Trivia'" class="trivia-carousel__feedback my-3">
              <div class="d-inline-flex align-items-center justify-content-center" :class="getFeedbackClass()">
                <p class="feedback-text mb-0"><small>{{getInteractionFeedbackText()}}</small></p>
                <div class="feedback-icon ml-3" v-if="isAnswerCorrect(containerContent, activeContent.interaction, getUserInteractionAnswerId(containerContent, activeContent.interaction))">
                  <i class="fal fa-check"></i>
                </div>
                <div class="feedback-icon ml-3" v-else>
                  <i class="fal fa-times"></i>
                </div>
              </div>
            </div>
          </transition>
					<div class="w-100 trivia-carousel" ref="trivia-carousel">
            <div v-for="(triviaContent, index) in content.trivia_carousel" class="px-3 trivia-carousel-item w-100 d-flex flex-column align-items-center justify-content-start justify-content-md-center trivia-question">
              <div class="col-lg-8 text-center">
                <div v-if="showNavigation" class="w-100 d-flex justify-content-center my-3 align-items-center position-relative trivia-carousel__progress">
                  <h4 class="h4 text-uppercase mb-0">
                    {{ft('ecommerce.question')}} {{carousel.selectedIndex + 1}} {{ft('ecommerce.of')}} {{content.trivia_carousel.length}}
                  </h4>
                </div>
                <h3 class="h2 trivia-question py-4 mb-0" :class="[getFontSizeClass()]" >{{triviaContent.question}}</h3>
              </div>
              <div class="col-lg-8 text-center">
                <ul class="list-unstyled">
                  <li v-for="(answer, answer_index) in triviaContent.answers" class="my-3">
                    <button 
                      v-if="triviaContent.interaction.type == 'Trivia'"
                      @click="updateTriviaInteractionCustomized(answer, triviaContent)" 
                      :class="[getAnswerClasses(answer, triviaContent), getFontSizeClass()]" 
                      class="btn btn-course-trivia px-3">
                      {{answer.text}}
                    </button>
                    <a 
                      href="javascript: void(0)" 
                      v-else
                      @click="updateVerusInteractionCustomized(answer, triviaContent)" 
                      :class="[getAnswerClasses(answer, triviaContent), {'active': isUserAnswer(answer.$id, triviaContent)}]" 
                      class="btn btn-course-trivia px-3">
                      <div class="d-flex" :class="[getFontSizeClass(), (isInteractionDone(containerContent, triviaContent.interaction) ? 'justify-content-between align-items-center' : 'justify-content-center')]" >
                        <span class="flex-grow-1">{{answer.text}}</span><span class="px-3" v-if="isInteractionDone(containerContent, triviaContent.interaction)">{{getAnswerPercentage(answer, triviaContent)}}%</span>
                      </div>
                      <div v-if="isInteractionDone(containerContent, triviaContent.interaction)" style="position: absolute; left: 0; bottom: 0; height: 5px; right: 0;" class="bg-dark">
                        <div class="bg-primary" :style="{'width': `${getAnswerPercentage(answer, triviaContent)}%`}" style="position: absolute; left: 0; bottom: 0; height: 5px;"></div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- last slide -->
            <div class="trivia-carousel-item w-100 h-100 d-flex justify-content-center align-items-center flex-column bg-light py-5 text-center">
              <course-countdown
                :content="content"
                :opt-container-content="containerContent"
                :initalCountdown="3"
                @next-bookmark="$emit('next-bookmark');"
                v-if="carousel && carousel.selectedIndex == carousel.cells.length - 1"
              >  
              </course-countdown>
            </div>
          </div>
				</div>
			</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[course-product-trivia-carousel-component] {

  .trivia-carousel-item {
    min-height: calc(75vw * 0.56);

    @include media-breakpoint-down(sm) {
      min-height: calc(100vh - #{$header-height-sm});
    }
  }

  .trivia-carousel__feedback {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    pointer-events: none;
    z-index: 1020;

    > div {
      color: $white;
      padding: 0.5rem 2rem;
      border-radius: 20px;
    }
  }

  .trivia-carousel__progress {
    top: 0;
    left: 0;
    z-index: 1;
    height: 2rem;
  }

  .trivia-question {
    &.big-fonts {
      font-size: 1.6rem;
    }
  }

  .navigation__next, .navigation__prev {
    align-items: center;
    background-color: #dcdcdcbb;
    border-radius: 50%;
    border: solid 1px $dark;;
    display: flex;
    height: 3.2rem;
    justify-content: center;
    opacity: 0.6;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.6s;
    width: 3.2rem;
    z-index: 5;

    &:not(.disabled) {
     &:hover {
        opacity: 1;
        cursor: pointer;
      }
    }

    .trivia-carousel__navigation-icon {
      font-size: 1.25rem;
      color: $dark;
    }
  }

  .navigation__next {
    right: 3rem;
  }

  .navigation__prev {
    left: 3rem;
  }

  .btn-course-trivia {
    position: relative;
    text-transform: uppercase;
    border-radius: 0.5rem;
    width: 28rem;
    font-size: 0.85rem;
    overflow: hidden;

    &.big-fonts {
      font-size: 1.2rem;
    }
  }

  .correct-answer {
    background-color: $green;
  }

  .wrong-answer {
    background-color: $red;
  }

  @include media-breakpoint-down(sm) {
    
    .trivia-carousel__progress {
      height: 3rem;
    }

    .btn-course-trivia {
       width: 100%;
    }

    .navigation__next, .navigation__prev {
      top: 2.5rem;
      height: 2.8rem;
      width: 2.8rem;

      .trivia-carousel__navigation-icon {
        font-size: 1rem;
      }
    }

    .navigation__next {
      right: 2rem;
    }

    .navigation__prev {
      left: 2rem;
    }

  }

}
</style>