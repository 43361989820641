<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
import Intersect from 'vue-intersect';
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  components: {
  	Intersect
  },
  data: function() {
    return {
    };
  },
  methods: {
    getContentResponsiveImageURL: Fandom.getContentResponsiveImageURL,
    toggleVideo(status) {
    	if (status) {
    		this.video.muted = true;
    		this.video.play();
    	} else {
    		this.video.pause();
    	}
    },
    toggleVideoPopup() {
      Vue.set(globalState, "videoPopupContent", { content: this.content, containerContent: this.containerContent });
    }
  },
  computed: {
  	video() {
  		return $(this.$refs.video)[0];
  	},
  	backgroundVideoUrl() {
      const videoDesktop = get(this, "content.background_video.url", false);
      return get(globalState, "viewport.xs", false) ? get(this, "content.background_video_mobile.url", videoDesktop) : videoDesktop;
    },
    isVideoPresent() {
      return !!this.content.play;
    },
    anchorId() {
    	return `anchor-${this.easyadminId.replace("|", "_")}`
    },
    backgroundClass() {
      const bgClass = this.content.background_class || this.content.theme || "gray-800";
      return bgClass.indexOf("bg-") >= 0 ? bgClass : `bg-${bgClass}`;
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="[contentType, backgroundClass]" :style="{backgroundColor: content.background_color}" v-if="isContentVisibleToUser()" :id="anchorId" stripe-one-col-media-component>
  	<div class="position-absolute-center w-100 h-100 bg-cover-center" :style="{'background-image': getContentResponsiveImageURL('background_image')}">
  	</div>
  	<intersect @enter="toggleVideo(true)" @leave="toggleVideo(false)">
	  	<div v-if="backgroundVideoUrl" class="video-16by9 position-absolute-center h-100">
	      <video loop webkit-playsinline playsinline ref="video">
	        <source :src="expandUrl(backgroundVideoUrl)" type="video/mp4">
	      </video>
	    </div>
  	</intersect>
    <div class="position-absolute-center linear-gradient" v-if="!content.hide_gradient"></div>
    <div class="position-absolute-center progressive-gradient" v-if="content.fading_gradient"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-8 d-flex flex-column align-items-center justify-content-center" :class="[{'mh-base': !content.height}, `text-${content.text_align || 'left'}`]" :style="[{'min-height': content.height}]">
        	<img v-if="content.icon" :src="expandUrl(content.icon.url)" class="mb-4 icon" />
          <component v-if="content.title" class="h1" :is="content.title_tag || 'h2'">{{content.title}}</component>
          <component v-if="content.description" :is="content.description_tag || 'div'" class="h3 font-weight-normal" v-html="content.description"></component>
          <a href="javascript: void(0)" v-if="isVideoPresent" @click="toggleVideoPopup()" class="text-white">
            <i class="fal fa-play-circle fa-6x"></i>
          </a>
          <a v-if="content.button" class="btn shadow-none mt-3" :class="themeButtonClass" :target="getTarget(content.button)" :href="content.button.url">
            {{content.button.title}}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped="">

[stripe-one-col-media-component] {
	position: relative;
	color: $white;

  .mh-base {
    min-height: 600px;
  }

	.icon {
    width: 18rem;
    max-width: 75%;
  }

  .progressive-gradient {
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(241,241,241,0) 100%);
  }
}
</style>