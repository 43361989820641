import { render, staticRenderFns } from "./course-product-streaming.vue?vue&type=template&id=2038fe25&scoped=true"
import script from "./course-product-streaming.vue?vue&type=script&lang=js"
export * from "./course-product-streaming.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2038fe25",
  null
  
)

export default component.exports