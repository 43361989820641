const hfarmCoursesAuthorizationMixin = {
  methods: {
    async getInheritContent(force=false) {
      if (this.inheritContent && !force) {
        // nothing to do
      } else if (this.content.url) {
        await Fandom.getContentsByNames(() => {}, { contents_names: [this.content.url.content] });
      }
    },
    mayRedirectOnContent(event) {     
      const authorization = globalState.pageModel.name_to_content_authorization[this.inheritContent.name];
      if (!authorization || authorization.authorized) {
        return true;
      } else {
        if (authorization.type === "registration" || !Fandom.isUserRegistered()) {
          $("#modal-authentication-required").modal("show");
        } else if (authorization.type === "user_group") {
          Fandom.emit('modal-user-group-required', { inheritContent: this.inheritContent });
        } else {
          return true;
        }
        if (event) { event.preventDefault(); }
        return false;
      }
    }
  }
}

export {
  hfarmCoursesAuthorizationMixin
};