<script>
import {
  fandomBasicMixin,
  fandomExtendedMixin,
} from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data: function () {
    return {};
  },
  computed: {
    headingStyle() {
      return {
        "background-image":
          this.getContentResponsiveImageURL("image"),
      };
    },
    rootStyle() {
      return {
        "background-image":
          this.getContentResponsiveImageURL("background_image"),
        "background-color": this.content.background_color,
      };
    },
  },
};
</script>

<template>
  <div
    v-easyadmin="easyadminId"
    :class="contentType"
    :style="rootStyle"
    v-if="isContentVisibleToUser()"
    page-article-podcast-component
  >
    <div class="bg-cover-center bg-primary py-4" :style="headingStyle">
      <div class="container">
        <div class="row justify-content-center">
          <div class="w-100 col-12 d-flex justify-content-center align-items-center content-container" v-html="content.embed_data"></div>
        </div>
      </div>
    </div>
    <component
      :is="stripe.layout"
      :content="stripe"
      v-for="stripe in content.stripes"
      :key="stripe.$id"
      :opt-container-content="containerContent"
    ></component>
  </div>
</template>

<style lang="scss">
[page-article-podcast-component] {
  background-size: 100% auto;

  .content-container {
    min-height: 600px;

    iframe {
      .player_title {
        color: white !important;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .content-container {
      min-height: auto;
    }
  }

}
</style>
