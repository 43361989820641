<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { interactionMixin } from "src/modules/interactions_module.js";

export default {
  mixins: [fandomBasicMixin, interactionMixin],
  props: {
    interaction: Object
  },
  methods: {
    getInteractionFeedbackText() {
      if (this.isInteractionFeedbackPhase(this.containerContent, this.interaction)) {
        return this.ft("globals.interaction.feedback_success_text");   
      }
    }
  }
};
</script>

<template>
  <div class="text-white fixed-top d-flex align-items-center justify-content-center mx-auto py-3 px-3 mt-3" feedback-alert>
    <div class="feedback-icon mr-3">
      <i class="fas fa-thumbs-up"></i>
    </div>
    <h2 class="feedback-text mb-0">{{getInteractionFeedbackText()}}</h2>
    <div></div>
  </div>
</template>

<style lang="scss" scoped>
$feedback-text-font-size: 1rem;
$feedback-icon-font-size: 1.5rem;
$feedback-width: 30rem;

[feedback-alert] {
  z-index: $zindex-feedback;
  width: $feedback-width;
  max-width: 100%;
  border-radius: 15rem;
  background-color: $success;

  .feedback-text {
    font-size: $feedback-text-font-size;
    font-weight: normal;
  }

  i {
    font-size: $feedback-icon-font-size;
  }
}
</style>
