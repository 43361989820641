<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { HfarmCourses } from '../../modules/hfarm_courses_module.js';

export default {
  mixins: [fandomBasicMixin],
  props: ["propBookmark"],
  data() { 
    return {
      bookmark: this.propBookmark,
      unitNumber: 1
    } 
  },
  methods: {
    setBookmark(chapter, index) {
      this.bookmark = chapter;
      this.unitNumber = index + 1;
      this.$emit('set-bookmark', { chapter: chapter, unitNumber: this.unitNumber });
    },
    isChapterActive(chapter) {
      return  this.bookmark.$id == chapter.$id;
    },
    getChapterIcon(chapter) {
      return HfarmCourses.getCourseSidebarIcon(this.containerContent, chapter);
    }
  }
};
</script>

<template>
  <div id="accordion" class="h-100 bg-gray-800" course-sidebar-component>
    <div class="course-sidebar__header p-4 d-flex justify-content-end d-lg-none">
      <a href="javascript: void(0)" @click="$emit('toggle-sidebar')" class="course-sidebar__close">
        <i class="far fa-times"></i>
      </a>
    </div>
    <div class="card bg-gray-800 px-3" v-for="(chapter, index) in content.chapters">
      <div class="card-header bg-gray-800">
        <h5 class="mb-0">
          <button
            :aria-expanded="(index + 1 == unitNumber)"
            data-toggle="collapse"
            :aria-controls="`collapse${chapter.$id}`"
            :data-target="`#course-chapter-${chapter.$id}`"
            class="btn btn-link collapsed w-100 course-index__item font-weight-bold d-flex justify-content-between px-0 py-2 rounded-0"
          >
            <div class="text-left">{{chapter.title}}</div>
            <small><i class="far fa-chevron-up"></i></small>
          </button>
        </h5>
      </div>
      <div :id="`course-chapter-${chapter.$id}`" class="collapse" :class="{'show': (index + 1 == unitNumber)}" aria-labelledby="headingOne" data-parent="#accordion">
        <div class="card-body p-0 bg-gray-800">
          <ul v-if="chapter.chapters" class="list-unstyled">
            <li v-for="subchapter in chapter.chapters" class="p-3 d-flex" :class="{'bg-gray-600': isChapterActive(subchapter)}">
              <div class="course-sidebar__item-icon pr-3">
                <i :class="getChapterIcon(subchapter)"></i>
              </div>
              <a href="javascript: void(0)" class="course-index__item secondary" @click="setBookmark(subchapter, index)">{{subchapter.title}}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[course-product-component] {

  .course-sidebar__header {
    color: $white;
    border-bottom: 1px solid $dark;

    h3 {
      font-size: $font-size-base;
    }

    .course-sidebar__close {
      color: $white;
    }
  }

  .course-sidebar__item-icon {
    i { color: $white; }
  }

  .course-index__item {
    text-transform: none;
    color: $white;
    font-weight: bold;
    font-size: $font-size-base;

    &.collapsed .far.fa-chevron-up {
      transform: rotate(180deg);
    }

    &.secondary {
      font-weight: normal;
    }
  }

  .card {
    border-radius: 0;
    border-right: none;
    border-color: $dark;
  }

  .card-header {
    border-bottom: none;
    border-radius: 0 !important;
  }

  .card:not(:first-child) {
    border-top: none;
  }

}
</style>