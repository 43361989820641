<script>
import { fandomTranslate } from "src/modules/common_module";
import { get } from 'lodash';

export default {
  data: function() {
    return {
      pageModel: globalState.pageModel,
      options: [
        {
          value: true,
          label: this.ft('globals.privacy_modal.accept')
        },
        {
          value: false,
          label: this.ft('globals.privacy_modal.decline')
        }
      ],
      privacy: null,
      errors: []
    };
  },
  mounted() {
    Vue.nextTick(() => {
      if (this.pageModel.user.privacy_update_needed) {
        $("#modal-update-privacy").modal({
          backdrop: 'static',
          keyboard: false
        });
      }
    });
  },
  methods: {
    ft: fandomTranslate,
    updatePrivacy() {
      Vue.set(this, "errors", []);
      Fandom.ajax({
        url: "/api/v5/set_privacy_agree_date",
        method: "POST",
        data: {
          privacy: this.privacy
        },
        success: (data) => {
          if (data.privacy_updated) {
            Vue.set(globalState.pageModel.user, 'privacy_update_needed', false);
            $("#modal-update-privacy").modal("hide");
          } else {
            this.errors.push(this.ft('globals.privacy_modal.privacy_required'))
          }
        }
      })
    }
  },
  computed: { }
};
</script>

<template>
  <div modal-update-privacy-component>
    <div id="modal-update-privacy" class="modal fade" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body text-center" registration-pages>
            <div class="row justify-content-center">
              <div class="col-lg-10 py-4">
                <h5 class="modal-title h2 mb-3">{{ft('globals.privacy_modal.modal_title')}}</h5>
                <div class="user-edit-description mb-3" v-html="ft('globals.privacy_modal.modal_description')"></div>
                <div class="form-group">
                  <div>
                    <div
                      class="custom-control custom-radio custom-control-inline"
                      v-for="(option, optionIndex) in options"
                      :key="`option-${optionIndex}`"
                    >
                      <input
                        type="radio"
                        :value="option.value"
                        v-model="privacy"
                        class="custom-control-input"
                        :required="!optionIndex"
                        :id="`privacy-${optionIndex}`"
                      >
                      <label
                        class="custom-control-label"
                        :class="{'font-weight-bold': privacy == option.value}"
                        :for="`privacy-${optionIndex}`"
                      >{{option.label}}</label>
                    </div>
                  </div>
                </div>
                <div class="w-100 d-flex justify-content-center mt-4">
                  <button class="btn btn-primary" @click="updatePrivacy()">{{ft('globals.privacy_modal.modal_button')}}</button>
                </div>
                <div class="alert alert-danger mt-3 mb-0" v-if="!!errors && errors.length && errors.length > 0">
                  <strong>{{ft('globals.errors_occurred')}}</strong>
                  <div v-for="(error, index) in errors" :key="`error-${index}`">
                    <span v-html="error"></span>
                  </div>
                </div>
              </div>
            </div>  
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[modal-update-privacy-component] {

  [generic-form-component]::v-deep {
    color: $dark;

    .form-control {
      border: 1px solid $gray-400;
    }

    .form-group {
      text-align: left;
    }
  }
}
</style>