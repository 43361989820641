import { get } from 'lodash';

/* courses sidebar icon management logic */
const layoutsToIconMethods = {
  'course-product-video-chapter': (containerContent, content) => {
    const interaction = get(content, ['play', 'play_third_quartile', 'interaction'], null);
    // if (!!<missing>videoPlayer.playing) {
    //   return "fas fa-play-circle"
    // }
    if (interaction) {
      const ui = get(globalState.pageModel, ['name_to_content_user_info', containerContent.name, 'id_to_interaction', interaction.id, 'user_interaction'], false);
      return !!ui ? "far fa-check-circle" : "far fa-circle";
    }
    return "far fa-circle";
  },
  'course-product-trivia-carousel': (containerContent, content) => {
    const interactions = get(content, ['trivia_carousel'], []).map( trivia => trivia.interaction);
    let interactionsDone = 0;
    interactions.forEach( interaction => {
      const ui = get(globalState.pageModel, ['name_to_content_user_info', containerContent.name, 'id_to_interaction', interaction.id, 'user_interaction'], false);
      if (!!ui) {
        interactionsDone += 1;
      }
    });
    return interactionsDone === interactions.length ? "far fa-check-circle" : "far fa-circle";
  },
  'course-product-badge': (containerContent, content) => {
    return "fal fa-certificate";
  },
  'course-product-trivia-result': (containerContent, content) => {
    const status = get(globalState.pageModel, ["name_to_content_user_info", containerContent.name, "status"]);
    return status == "completed" ? "far fa-check-circle" : "far fa-circle";
  }
}

function getCourseSidebarIcon(containerContent, chapter) {
  const iconMethod = layoutsToIconMethods[chapter.layout];
  if (iconMethod) {
    return iconMethod(containerContent, chapter);
  } else {
    return "far fa-circle";
  }
}

const HfarmCourses = {
  getCourseSidebarIcon
};

export { HfarmCourses };
