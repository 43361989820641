<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js'
import { interactionMixin } from 'src/modules/interactions_module.js'
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, interactionMixin],
  mounted() {
    if (this.isInteractionDone(this.containerContent, this.content.interaction)) {
      setTimeout(() => {
        this.$emit("interaction-done", {
          contentUserInfo: this.getContentUserInfo(this.containerContent),
          interactionId: this.content.interaction.id
        });
      }, 3000);
    }
  },
  watch: {
    // The feedback phase is managed by the interaction module. When it ends the parent is notify to continue the video
    isThisInteractionFeedbackPhase(newValue, previousValue) {
      if (previousValue && !newValue) {
        this.$emit("interaction-done", {});
      }
    }
  },
  computed: {
    isThisInteractionFeedbackPhase() {
      return this.isInteractionFeedbackPhase(this.containerContent, this.content.interaction);
    }
  },
  methods: {
    getAnswerClasses(answer) {
      if (this.isInteractionDone(this.containerContent, this.content.interaction)) {
        if (this.isAnswerCorrect(this.containerContent, this.content.interaction, answer.$id)) {
          return "btn-success";
        } else {
          const userInteraction = this.getUserInteraction(this.containerContent, this.content.interaction);
          const userInteractionAnswerId = Fandom.exists(userInteraction.answer_id) ? userInteraction.answer_id : parseInt(userInteraction.data.answer_id);
          if (this.content.interaction.type == "TestStep") {
            return userInteractionAnswerId == answer.$id ? "btn-light" : "btn-default";
          } else {
            return userInteractionAnswerId == answer.$id ? "btn-danger" : "btn-default";
          }
        } 
      } else {
        return "btn-default";
      }
    },
    getMinHeight() {
      if(Fandom.isSafari() && this.globalState.viewport.xs) {
        return "85vh";
      } else {
        return "100vh";
      }
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" class="bg-cover-center" :class="contentType" v-if="isContentVisibleToUser()" :style="{'background-image': getContentResponsiveImageURL('background_image'), 'background-color': content.background_color}" interaction-trivia-component>
    <div class="container">
      <div class="row align-items-center justify-content-center py-4" :style="{'min-height': getMinHeight()}">
        <div class="col-md-8 text-center">
          <h2 class="pb-sm-4 pb-3 mb-0" :style="{'color': content.text_color}">{{content.question}}</h2>
          <div class="row">
            <div class="col-md-6 px-5 py-2 p-sm-3" v-for="answer in content.answers">
              <button  @click="updateTriviaInteraction(containerContent, content, content.interaction, answer.$id)" class="btn btn-trivia-answer w-100 position-relative" :class="getAnswerClasses(answer)">
                {{answer.text}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
      <component :is="content.feedback_layout || 'interaction-trivia-feedback-alert'" v-if="isInteractionFeedbackPhase(containerContent, content.interaction)" :content="content" :opt-container-content="containerContent" :interaction="content.interaction"></component>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
[interaction-trivia-component] {
  background-color: $trivia-component-bg-color;
  color: $trivia-component-color;
  overflow: scroll;
  max-height: 100vh;

  .btn-trivia-answer {
    background-color: transparent;
    color: $white;
    border: 1px solid $white;
    padding-top: 1rem;
    padding-bottom: 1rem;
    min-height: 5rem;
    font-size: 0.85rem;

    @include media-breakpoint-down(sm) {
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      min-height: 4rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    &.btn-light {
      background-color: $white;
      border-color: $white;
      color: $dark;
    }

    &.btn-danger {
      background-color: $danger;
      border-color: $danger;
    }

    &.btn-success {
      background-color: $success;
      border-color: $success;
    }
  }
}
</style>
