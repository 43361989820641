<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
import { liveEventsMixinV2 } from 'src/modules/live_events_v2_module.js';
import { isInteractionDone } from 'src/modules/interactions_module.js';
import { get, cloneDeep } from 'lodash';

import { EmbedMultistream, EmbedAdvanced } from 'embed';
import PageLiveEventTriviaGameModal from "page-live-event-trivia-game-modal";
import PageLiveEventSurvey from "page-live-event-survey";
import PageLiveEventAlert from "page-live-event-alert";
import PageLiveEventInteractiveSidebar from "page-live-event-interactive-sidebar";

export default {
  mixins: [fandomBasicMixin, liveEventsMixinV2, fandomExtendedMixin],
  components: {
    EmbedMultistream, EmbedAdvanced,
    PageLiveEventTriviaGameModal,
    PageLiveEventSurvey,
    PageLiveEventAlert,
    PageLiveEventInteractiveSidebar
  },
  data() {
    return {
      // notices are buttons clickable on the video content
      // they are objects made this way: {id, message, callback}
      notices: [],
      activeInteraction: null,
      embed: {
        fullscreen: false,
        unsupportedFullscreenClass: ""
      },
      isSidebarOpen: false,
      activeSidebarTab: undefined
    }
  },
  methods: {
    getRecordOrContent: Fandom.getRecordOrContent,
    removeNotice(noticeId) {
      Vue.set(this, 'notices', this.notices.filter(notice => notice.id != noticeId));
    },
    mayAddPermaNotice(noticeId, data, callback) {
      if (!this.notices.map(notice => notice.id).includes(noticeId)) {
        this.notices.push({
          description: data.description,
          message: data.message,
          callback: callback,
          icon: data.icon,
          id: noticeId
        });
      }
    },
    closeModalHandler(target, record, icon) {
      this.mayAddPermaNotice(
        `${target}-modal-${record.id || record.$id}`,
        {
          message: this.ft(`page_live_event.${target}.notice`),
          description: this.ft(`page_live_event.${target}.notice_description`),
          icon: icon
        },
        () => { this.$refs[target].openModal() }
      )
    },
    handleToggleFullscreen(data) {
      Vue.set(this.embed, 'unsupportedFullscreenClass', data.fallback ? "unsupported-fullscreen" : "");
      Vue.set(this.embed, 'fullscreen', data.active);
      Vue.nextTick(() => {
        const fn = this.embed.fullscreen.active ? "addClass" : "removeClass";
        $('body')[fn]('fullscreen');
      });
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    mayAddTempNotice(noticeId, data) {
      if (!this.notices.map(notice => notice.id).includes(noticeId)) {
        this.notices.push({...data, id: noticeId});
        setTimeout(() => {
          this.removeNotice(noticeId);
        }, 15000);
      }
    },
    handleClickNotice(noticeId) {
      const notice = this.notices.find(notice => notice.id === noticeId);
      if (notice.type) {
        this.isSidebarOpen = true;
        Vue.set(this, 'activeSidebarTab', notice.type);
      } else if (notice.callback) {
        notice.callback();
      }
      this.removeNotice(noticeId);
    },
    newInteractionHandler(data) {
      const interactionRecord = cloneDeep(data.interactionRecord);
      const noticeId = `interactions-modal-${interactionRecord.id || interactionRecord.$id}`;
      this.mayAddTempNotice(
        noticeId,
        {
          message: this.ft('page_live_event.interactions.notice'),
          icon: 'far fa-poll',
          interaction: interactionRecord,
          type: data.type
        }
      )
    },
    gridStyle() {
      const style= [];
      if (!this.showSidebar) {
        style.push('grid-template-columns: 1fr;')
      }

      return style;
    }
  },
  computed: {
    liveInteractions() {
      return get(this.interactiveContent, ["live_interactions"], []);
    },
    isIPhone() {
      return /iPhone/i.test(navigator.userAgent);
    },
    alert() {
      return this.getRecordOrContent(get(this.interactiveContent, ["alert"], false)) || this.getRecordOrContent(get(this.content, ["alert"], false));
    },
    survey() {
      return this.getRecordOrContent(get(this.interactiveContent, ["survey"], false)) || this.getRecordOrContent(get(this.content, ["survey"], false));
    },
    interactiveContent() {
      return this.getRecordOrContent(this.content.interactive_content);
    },
    showSidebar() {
      return !!this.interactiveContent && !(!this.interactiveContent.live_interactions && !this.interactiveContent.comments && (!this.interactiveContent.chapters || this.interactiveContent.chapters.length == 0));
    },
    interactiveContentContainer() {
      return typeof this.content.interactive_content == 'string' ? this.interactiveContent : this.containerContent;
    },
    waitingGame() {
      return this.getRecordOrContent(get(this.interactiveContent, ["waiting_game"], false)) || this.getRecordOrContent(get(this.content, ["waiting_game"], false));
    }
  },
  watch: {
    liveInteractions(newVal, oldVal) {
      if (newVal.length > oldVal.length) {
        const record = newVal[newVal.length - 1];
        if (!isInteractionDone(this.interactiveContentContainer, record.interaction)) {
          this.newInteractionHandler({
            elId: `anchor-${this.containerContent.id}-${record.interaction.id}`,
            interactionRecord: record,
            type: 'live_interactions'
          });
        }
      }
    }
  }
};
</script>

<template>
  <div 
    v-easyadmin="easyadminId"
    :style="theme"
    v-if="isContentVisibleToUser()"
    :class="contentType"
    page-live-event-component
  >
    <div class="container-fluid">
      <div class="row">
        <div
          ref="fullscreenContainer" 
          class="fullscreen-container grid w-100"
          :class="[embed.unsupportedFullscreenClass, {'no-sidebar': !showSidebar, 'embed-fullscreen': embed.fullscreen, 'active-side-nav': isSidebarOpen}]"
          :style="gridStyle()"
        >
          <component 
            class="embed-main-content" 
            :is="content.play_streams ? 'embed-multistream' : 'embed-advanced'"
            :class="{'container-lg px-0': !showSidebar && !embed.fullscreen, 'active-side-nav': isSidebarOpen}"
            :content="content"
            :opt-container-content="containerContent"
            :notices="notices"
            :fullscreen="embed.fullscreen"
            fullscreen-target-class="fullscreen-container"
            :unsupported-fullscreen-class="embed.unsupportedFullscreenClass"
            @fplayer:toggleFullscreen="handleToggleFullscreen"
            @click-notice="handleClickNotice"
          >
            <page-live-event-trivia-game-modal
              ref="waiting_game"
              v-if="waitingGame"
              :content="waitingGame"
              :opt-container-content="containerContent"
              @close="closeModalHandler('waiting_game', waitingGame, 'far fa-trophy-alt')"
              @clearnotice="removeNotice(`waiting_game-modal-${$event.id}`)"
            ></page-live-event-trivia-game-modal>
            <page-live-event-survey
              ref="survey"
              v-if="survey"
              :content="survey"
              :opt-container-content="containerContent"
              @close="closeModalHandler('survey', survey, 'far fa-poll')"
              @clearnotice="removeNotice(`survey-modal-${$event.id}`)"
            ></page-live-event-survey>
            <page-live-event-alert
              ref="alert"
              v-if="alert"
              :content="alert"
              :opt-container-content="containerContent"
              @close="closeModalHandler('alert', alert, 'far fa-info-circle')"
              @clearnotice="removeNotice(`alert-modal-${$event.id}`)"
            ></page-live-event-alert>
            <template #actions="{showControls}">
              <transition enter-active-class="animated slideInUp" leave-active-class="animated slideOutDown">
                <div id="show-sidebar-action" class="actions-bottom p-medium p-lg-double" :class="{'actions-bottom--controlbar': showControls}" v-show="embed.fullscreen">
                  <div 
                    v-if="showSidebar"
                    @click="toggleSidebar"
                    class="actions-bottom__sidebar bg-white rounded-circle"
                  >
                    <i class="fal fa-hand-pointer"></i>
                  </div>
                </div>
              </transition>
            </template>
          </component>
          <div class="px-0 live-event-interactive-sidebar" v-if="showSidebar">
            <page-live-event-interactive-sidebar
              :content="interactiveContent"
              :opt-container-content="interactiveContentContainer"
              :fullscreen="embed.fullscreen"
              v-model="activeSidebarTab"
            ></page-live-event-interactive-sidebar>
          </div>
          <div class="stripes">
            <component :is="stripe.layout" :content="stripe" v-for="(stripe) in content.stripes" :key="stripe.$id" :opt-container-content="containerContent"></component>
          </div>
        </div>
        <!-- FIX iPhone scroll to top which shows toolbar on fullscreen -->
        <div v-if="isIPhone && embed.fullscreen" class="backscroll"></div>
      </div>
    </div>
  </div>
</template>


<style lang="scss">
[page-live-event-component] {
  // OVERRIDE inner-height CSS var to match fandom player height which is correct
  --inner-height: calc(var(--vh, 1vh) * 100);
  --sidebar-width: 26.5rem;

  .fullscreen-container {
    background-color: var(--theme-background);
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr var(--sidebar-width);
    grid-template-rows: repeat(2, auto);
    grid-auto-rows: auto;
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    &.no-sidebar {
      display: flex;
      flex-direction: column;
    }

    .embed-main-content { grid-area: 1 / 1 / 2 / 2; }
    .live-event-interactive-sidebar { grid-area: 1 / 2 / 3 / 3; }
    .stripes { grid-area: 2 / 1 / 3 / 2; }

    @include media-breakpoint-down(md) {
      grid-template-rows: repeat(3, auto);
      grid-template-columns: 1fr;

      .embed-main-content { grid-area: 1 / 1 / 2 / 2; }
      .live-event-interactive-sidebar { grid-area: 2 / 1 / 3 / 2; }
      .stripes { grid-area: 3 / 1 / 4 / 2; }

      @media screen and (orientation:landscape) {
        --sidebar-width: 40%;

        .backscroll {
          display: block;
          height: var(--inner-height);
        }
      }
    }

    &.embed-fullscreen {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      position: relative;
      background-color: $black;

      &.unsupported-fullscreen {
        position: fixed !important;
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        z-index: 1090;
        background-color: $black;
        height: var(--inner-height);
        min-height: var(--inner-height);
        max-height: var(--inner-height);
        flex: 1 1 var(--inner-height);

        .embed_content {
          height: 100% !important;
        }
      }

      .embed-main-content {
        width: 100%;
        transition: all .4s ease-in-out;
      }

      .actions, .actions-bottom {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 2rem;
        z-index: 1;

        i {
          cursor: pointer;
        }
      }

      .show-controls + .actions-bottom {
        bottom: calc(#{$player-control-bar-height} + #{$spacer * 2});

        @include media-breakpoint-down(md) {
          bottom: calc(#{$player-control-bar-height} + #{$spacer * 1.25});
        }
      }

      .actions-bottom {
        transition: bottom .4s;
        bottom: 1rem;
        top: auto;
        z-index: 9999;

        &--controlbar {
          bottom: 50px;
        }

        &__sidebar {
          cursor: pointer;
          font-size: 1.5rem;
          width: 2.5rem;
          height: 2.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .live-event-interactive-sidebar {
        z-index: 1090;
        padding: 0;
        top: 0;
        right: 0;
        width: var(--sidebar-width);
        display: flex;
        flex-direction: column;
        margin-right: calc(-1 * var(--sidebar-width));
        transition: all .4s ease-in-out;
      }

      &.active-side-nav {
        .embed-main-content {
          width: calc(100% - #{var(--sidebar-width)});
        }

        .live-event-interactive-sidebar {
          margin-right: 0;
        }
      }

      @include media-breakpoint-down(xs) {
        display: grid;

        .embed-main-content {
          min-height: var(--inner-height);
        }

        .live-event-interactive-sidebar {
          width: 100%;
          height: calc(var(--inner-height) - (100vw / 16 * 9));
        }

        &.active-side-nav {
          .embed-main-content {
            width: 100%;
            min-height: calc(100vw / 16 * 9);
          }
        }
      }

      .stripes {
        display: none;
      }
    }

    [comments-component] {
      [comment-add-component] {
        .form-container {
          position: relative;

          .emoji-picker-wrapper {
            top: 50px;
          }

          form {
            width: 100%;

            .new-comment-area {
              border: 1px solid !important;
            }
          }
        }
      }

      [comment] {
        .comment-preview {
          &__name, &__text {
            color: var(--theme-color);
            text-shadow: none;
          }

          .fa-reply {
            color: var(--theme-color);
          }
        }

        .comment__with-parent {
          &::before {
            background-color: var(--theme-color);
          }

          .parent-comment::before {
            background: black;
          }
        }
      }
    }
  }

  .backscroll {
    display: none;
  }

  // styles to apply to all modals in this place
  // TODO: better put it somewhere else?
  [modal] {
    .live-event-modal__background {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: fixed;
      pointer-events: none;
      z-index: $zindex-modal-backdrop;
      background-color: rgba($black, 0.75);
    }

    .live-event-modal {
      top: 0;
      left: 0;
      position: fixed;
      z-index: $zindex-modal;
    }
  }
  @keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: .75;}
  }

  @keyframes fadeOut {
    0% {opacity: .75;}
    100% {opacity: 0;}
  }

  .player-side {
    display: none !important;
  }
}
</style>
