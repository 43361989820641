<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
import { hfarmCoursesAuthorizationMixin } from '../../modules/hfarm_courses_mixins_module.js';
import { interactionMixin } from 'src/modules/interactions_module.js';
import Intersect from 'vue-intersect';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin, interactionMixin, hfarmCoursesAuthorizationMixin],
  components: {
    Intersect
  },
  data: function() {
    return {
      headingInPage: true
    };
  },
  async mounted() {
    await this.getInheritContent(false);
    Vue.nextTick(() => {
      // If I arrive from an access, I propose the authorization modal when present
      this.authorizationRequired();
    });
    Fandom.onEvent("modal-user-group-succeeded", async () => {
      await this.getInheritContent(true);
      if (this.mayRedirectOnContent()) {
        window.location.href = this.getInheritContentURL;
      }
    });
  },
  methods: {
    authorizationRequired() {
      if (Fandom.isUserRegistered() && (Fandom.getUrlParameter("from_user_sign_in") || Fandom.getUrlParameter("from_user_sign_up"))) {
        if (this.mayRedirectOnContent()) {
          window.location.href = this.getInheritContentURL;
        }
      }
    },
    authorContent(authors) {
      const result = { items: [] };

      // Changelog 20210518
      // Mapping between the old component and the new one
      authors.forEach(author => {
        result.items.push({
          $id: author.$id,
          bio: author.member_bio,
          name: author.member_name,
          photo: author.member_photo,
          role: author.member_role
        });
      });

      return result;
    }
  },
  computed: {
    inheritContent() {
      return Fandom.getContent(this.content.url.content);
    },
    getInheritContentURL() {
      return this.content && this.content.url ? this.content.url.url : "javascript: void(0)";
    },
    comingSoon() {
      return !!this.content.valid_from && (new Date(this.content.valid_from)).getTime() > (new Date()).getTime();
    },
    authors() {
      if (!this.content.author) {
        return [];
      } else if (Array.isArray(this.content.author)) {
        return this.content.author;
      } else {
        return [this.content.author];
      }
    },
  }
};
</script>

<template>
	<div v-easyadmin="easyadminId" v-if="isContentVisibleToUser()" :style="theme" :class="contentType" class="themed-item" course-product-ecommerce-component>
		<intersect @enter="headingInPage = true" @leave="headingInPage = false">
      <div class="product-section">
  			<div class="container-fluid">
  				<div class="row justify-content-center">
  					<div class="col-12 col-md px-0 d-flex align-items-center bg-dark">
  						<component 
                v-if="exists(content.play)" 
                class="embed-responsive embed-responsive-16by9" 
                :is="`embed-${content.play.media_type}`"  
                :content="content" 
                :opt-container-content="containerContent">
              </component>  
              <div v-else-if="content.image" class="bg-cover-center ar-16by9 w-100" :style="{'background-image': getContentResponsiveImageURL('image')}"></div>
  					</div>
            <div class="col-12 col-md-6 col-lg-4 bg-gray-800 product-section__r">
              <div class="row justify-content-center align-items-center h-100">
                <div class="col-auto col-md-10 col-xl-8 py-5">
                  <h1 class="h1 mb-4" v-if="content.title">{{content.title}}</h1>
                  <div class="mb-4" v-if="!comingSoon">
                    <a :href="getInheritContentURL" @click="mayRedirectOnContent($event)" class="btn btn-primary text-uppercase">
                      {{content.alternative_registration_button || ft('ecommerce.go_to_course')}}
                    </a>
                  </div>
                  <div v-if="content.ical" class="mt-3 d-flex flex-column">
                    <a v-if="content.ical" href="javascript:void(0)" @click="updateDownloadInteraction(content, content.ical, content.ical.interaction, () => {}, content.ical.attachment.url)"><i class="far fa-calendar-alt mr-2"></i>{{content.ical.title || ft("ecommerce.save_the_date_ical")}}</a>
                  </div>
                </div>
              </div>
            </div>
  				</div>
  			</div>
  		</div>
    </intersect>
    <div class="w-100 themed-item-reverse">
      <div class="container py-4" v-if="content.description">
        <div class="row justify-content-center">
          <div class="col-12 col-md-8 py-4">
            <div v-html="content.description"></div>
          </div>
        </div>
      </div>
      <div class="container" v-if="authors.length > 0">
        <list-people :content="authorContent(authors)" :opt-container-content="containerContent" component-class="w-100"></list-people>
      </div>
      <component :is="stripe.layout" :content="stripe" v-for="(stripe, index) in content.stripes" :key="stripe.$id" :opt-container-content="containerContent"></component>
    </div>
    <transition enter-active-class="animated slideInUp" leave-active-class="animated faster slideOutDown d-01">
      <div class="bottom-bar py-4 bg-gray-800 d-flex align-items-center justify-content-center" v-if="!headingInPage">
        <a :href="getInheritContentURL" @click="mayRedirectOnContent($event)" class="btn btn-primary text-uppercase">
          {{content.alternative_registration_button || ft('ecommerce.go_to_course')}}
        </a>
      </div>
    </transition>
	</div>
</template>

<style lang="scss" scoped>
[course-product-ecommerce-component] {

	.product-section:nth-child(even) {
    background-color: $gray-200;
	}

  .product-section__r {
    color: var(--theme-color);

    a:not(.btn) {
      color: var(--theme-color) !important;
      text-decoration: underline;
    }
  }

  .bottom-bar {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100vw;
    z-index: 1;

    color: var(--theme-color);
  }

  @include media-breakpoint-down(sm) {
    .btn {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
}
</style>