<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js'
import { interactionMixin } from 'src/modules/interactions_module.js'
import {InteractionWidgetWrapper} from 'interaction';

import { get, throttle } from "lodash"
import VueWordCloud from 'vuewordcloud';

export default {
  components: {
    VueWordCloud,
    InteractionWidgetWrapper
  },
  mixins: [fandomBasicMixin, fandomExtendedMixin, interactionMixin],
  data() {
    return {
      cloudData: null
    }
  },
  mounted() {
    this.getCloudData();
  },
  methods: {
    getAnswerClasses(answer) {
      const classes = []
      if (this.showResults) {
        const hasCorrect = this.content?.has_correct_answer ?? false;
        classes.push(this.getAnswerClass(answer, hasCorrect), 'not-repeatable');
      } else {
        const notRepeatable = !this.checkRepeatable(this.containerContent, this.content, this.content.interaction);
        classes.push(this.getAnswerClass(answer), {'not-repeatable': notRepeatable});
      }

      if (this.votingClosed) {
        classes.push('voting-closed');
      }
      return classes;
    },
    getAnswerClass(answer, withCorrect=false) {
      const statusAnswer = withCorrect ? (this.isAnswerCorrect(this.containerContent, this.content.interaction, answer.$id) ? 'correct' : 'wrong') : 'no-status';

      return [
        statusAnswer,
        {'choose': this.userAnswerId == answer.$id}
      ]
    },
    getAnswerPercentage(answer) {
      const contentUserInfo = this.getContentUserInfo(this.containerContent);
      let percentage = get(contentUserInfo.id_to_interaction[this.content.interaction.id], ["id_to_answer", answer.$id, "percentage"], 0);
      return percentage.toFixed(0);
    },
    progressWidthStyle(percentage){
      return {width: `${percentage}%`};
    },
    setAnswer(answer) {
      this.updateVersusInteraction(this.containerContent, this.content, this.content.interaction, answer.$id, null, this.getCloudData);
    },
    cloudColor(data) {
      return data.weight <= 20 ? '#abb1b7' 
        : data.weight <= 40 ? '#969da5'
        : data.weight <= 60 ? '#818a93'
        : data.weight <= 80 ? '#6c7681'
        : '#4767a9';
    },
    getCloudData() {
      if (this.isCloud) {
        this.cloudData = this.content.answers
          .map(answer => {
            return {
              text: answer.text,
              weight: parseInt(this.getAnswerPercentage(answer)) || 1
            }
          })
      }
    },
    throttledCloudData: throttle(function() { this.getCloudData() }, 10000, {leading: true, trailing: true})
  },
  computed: { 
    isCloud() {
      return get(this.content, 'cloud_tag', false);
    },
    userAnswerId() {
      if (!this.isInteractionDone(this.containerContent, this.content.interaction)) {
        return null;
      }

      const userInteraction = this.getUserInteraction(this.containerContent, this.content.interaction);
      return Fandom.exists(userInteraction.answer_id) ? userInteraction.answer_id : parseInt(userInteraction.data.answer_id);
    }
  },
  watch: {
    "globalState.pageModel.name_to_content_user_info": {
      handler() {
        this.throttledCloudData();
      },
      deep: true
    }
  }
};
</script>

<template>
  <interaction-widget-wrapper
    :content="content"
    :opt-container-content="containerContent"
    :waiting-title="ft('interaction_versus_widget.feedback.title')"
    :waiting-description="ft('interaction_versus_widget.feedback.description')"
    versus-widget-component
  >
    <p class="question-title h4 mb-2">{{questionTitle}}</p>
    <p class="mb-3 text-center small" v-if="!showResults && content.question_subtitle">{{content.question_subtitle}}</p>
    <div class="row justify-content-center">
      <div v-if="!(showResults && isCloud)" class="col-12">
        <div
          class="border answer w-100 my-2 py-2 d-flex justify-content-between align-items-center"
          :class="getAnswerClasses(answer)"
          v-for="answer in content.answers"
          :key="`answer-${answer.$id}`"
          @click="setAnswer(answer)"
        >
          <div class="px-3 flex-grow-1 answer__text">{{answer.text}}</div>
          <div v-if="userAnswerId===answer.$id && !showResults" class="pr-3"><i class="fal fa-check"></i></div>
          <template v-if="showResults">
            <div class="pr-3 answer__percentage">
              <div>{{getAnswerPercentage(answer)}}%</div>
            </div>
            <div class="progress-bar-box progress w-100 h-100 position-absolute">
              <div class="progress-bar" :style="progressWidthStyle(getAnswerPercentage(answer))" role="progressbar" :aria-valuenow="getAnswerPercentage(answer)" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </template>
        </div>
      </div>
      <div v-else class="col-12">
        <div class="word-cloud-container w-100 p-4">
          <vue-word-cloud
            class="h-100 w-100"
            :words="cloudData"
            :color="cloudColor"
            font-family="Tekne LDO"
            :spacing="2" 
            :font-size-ratio="3"
            :animation-duration="4000"
            animation-easing="ease"
          />
        </div>
      </div>
    </div>
  </interaction-widget-wrapper>
</template>

<style lang="scss">
[versus-widget-component] {
  .progress-bar-box {
    right: 0;
    bottom: 0;
    background-color: transparent;

    .progress-bar {
      background-color: #ced4da;
    }
  }

  .wait-message {
    background-color: $gray-300;
    color: $dark;
    z-index: 10;
  }

  .question-title {
    color: var(--theme-color);
    text-align: center
  }

  .answer {
    transition: all $animation-time-fast linear;
    border-radius: 0.625rem;
    position: relative;
    overflow: hidden;
    background-color: var(--theme-background);
    cursor: pointer;
    
    &__text {
      z-index: 1;
      overflow-wrap: break-word;
      overflow: hidden;
    }

    &__percentage {
      z-index: 1;
      max-width: 95px;
      text-align: right;
    }

    &:hover, &.choose {
      background-color: #6c757d;
      color: var(--theme-background);
    }

    // Poll - user answer 
    &.choose.no-status {
      .progress-bar-box {
        background-color: var(--player-grey);

        .progress-bar {
          background-color: #6c757d;
        }
      }
    }

    // Quiz
    &.correct {
      font-weight: 600;

      .progress-bar-box {
        background-color: $correct-color;

        .progress-bar {
          background-color: $correct-dark-color;
        }
      }

      &.choose {
        color: white !important;
      }
    }

    // Quiz - user answer
    &.choose.wrong {
      color: white !important;
      font-weight: 600;

      .progress-bar-box {
        background-color: $wrong-color;

        .progress-bar {
          background-color: $wrong-dark-color;
        }
      }
    }

    &.voting-closed, &.not-repeatable {
      cursor: auto;
      border: none !important;

      &:not(.choose):hover {
        color: var(--theme-color);
      }
    }
  }

  .word-cloud-container {
    height: 340px !important;
    background-color: var(--theme-background);
    border-radius: 12px;
  }

}
</style>
